import * as docx          from 'docx';
import { orderBy }        from 'lodash';
import saveAs             from 'file-saver';
import DateFilter         from 'vue-app/shared/lib/date-filter.js';
import RfqSuggestedLawyer from 'resources/rfq-suggested-lawyer';

class RfqToDocx {
  constructor(rfq) {
    this.rfq = rfq;
  }

  generateDocx() {
    const doc = new docx.Document({
      sections: [
        {
          children: [
            ...this.buildHeader(),
            ...this.buildSelectedFirms(),
            ...this.buildProjectDetails(),
            ...this.buildQuestionGroups(),
            ...this.buildRecipients()
          ]
        }
      ],
      styles: {
        default: {
          document: {
            run: {
              font: 'Helvetica'
            },
            paragraph: {
              font: 'Helvetica'
            }
          }
        }
      }
    });

    docx.Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `RFP#${this.rfq.id} - Draft - ${DateFilter.filterDate(new Date())}.docx`);
    });
  }

  buildHeader() {
    const children = [];

    children.push(this.newTextRun({ text: this.rfq.name, size: 36, bold: true }));
    children.push(this.newTextRun({ text: '', break: 1 }));
    children.push(this.newTextRun({ text: `RFP draft downloaded on ${DateFilter.filterDate(new Date())}`, size: 24, italics: true }));

    return [new docx.Paragraph({ children: children })];
  }

  buildSelectedFirms() {
    const elements = [];

    const sectionInfo = new docx.Paragraph({
      children: [
        this.newTextRun({ text: 'Recipients', size: 28, bold: true, break: 2, underline: { type: 'single', color: '000000', bold: true } })
      ]
    });

    elements.push(sectionInfo);

    if (this.rfq.lawFirmQuotes.length > 0) {
      sectionInfo.addChildElement(this.newTextRun({ text: 'Your Firms', size: 24, bold: true, break: 2 }));

      this.rfq.lawFirmQuotes.forEach((quote) => {
        const lawFirmName = new docx.Paragraph({
          children: [
            this.newTextRun({ text: quote.firmName, size: 20, bold: true, italics: true })
          ]
        });

        if (quote.suggestedLawyers.length > 0) {
          const rowData = this.suggestedLawyerRowData(quote.suggestedLawyers);
          const suggestedLawyerTable = this.table(this.buildRows(rowData));

          elements.push(lawFirmName);
          elements.push(suggestedLawyerTable);
        }
        else {
          const noLawyersText = this.noLawyersText();

          lawFirmName.addChildElement(noLawyersText);
          elements.push(lawFirmName);
        }
      });
    }

    if (this.rfq.sentToMarketplace) {
      const marketplaceInfo = new docx.Paragraph({
        children: [
          this.newTextRun({ text: 'Priori\'s Talent Marketplace', size: 24, break: 2, bold: true }),
          this.newTextRun({ text: 'Included in proposal', italics: true, break: 2 })
        ]
      });

      elements.push(marketplaceInfo);
    }

    elements.push(new docx.Paragraph({ children: [this.newTextRun({ text: '', break: 1 })] }));

    return elements;
  }

  table(rows) {
    return new docx.Table({
      rows: [
        ...rows
      ],
      columns: 3,
      columnWidths: [2500, 5000, 2500],
      width: {
        size: 100,
        type: docx.WidthType.PERCENTAGE
      }
    });
  }

  noLawyersText() {
    return this.newTextRun({ text: 'You have not suggested any lawyers from this firm.', italics: true, size: 20 });
  }

  buildProjectDetails() {
    const children = [];

    children.push(this.newTextRun({ text: 'Project Details', size: 28, bold: true, underline: { type: 'single', color: '000000', bold: true } }));
    children.push(this.newTextRun({ text: 'RFP Name', bold: true, break: 2 }));
    children.push(this.textRunFor('name'));
    children.push(this.newTextRun({ text: 'RFP Created By', bold: true, break: 2 }));
    children.push(this.textRunFor('creatorFullName'));
    children.push(this.newTextRun({ text: 'Practice Group', bold: true, break: 2 }));
    children.push(this.practiceGroupText());
    children.push(this.newTextRun({ text: 'Description of Work', bold: true, break: 2 }));
    children.push(this.textRunFor('descriptionOfWork'));
    children.push(this.newTextRun({ text: 'Any relevant opposing/counterparty details, including counsel', bold: true, break: 2 }));
    children.push(this.textRunFor('counterpartyDetails'));
    children.push(this.newTextRun({ text: 'Estimated Timeline', bold: true, break: 2 }));
    children.push(this.textRunFor('estimatedTimeline'));
    children.push(this.newTextRun({ text: 'Budget (If any)', bold: true, break: 2 }));
    children.push(this.budgetText());
    children.push(this.newTextRun({ text: 'Preferred Rate Structure', bold: true, break: 2 }));
    children.push(this.textRunFor('preferredRateStructure'));
    children.push(this.newTextRun({ text: 'Additional Information', bold: true, break: 2 }));
    children.push(this.textRunFor('additionalInformation'));
    children.push(this.newTextRun({ text: 'Supporting Documents', bold: true, break: 2 }));
    children.push(...this.documentLinks(this.rfq.supportingDocuments));
    children.push(this.newTextRun({ text: 'Responses Due By', bold: true, break: 2 }));
    children.push(this.newTextRun({ text: DateFilter.filterDate(this.rfq.dueDate), break: 1.5 }));

    return [new docx.Paragraph({ children: children })];
  }

  buildQuestionGroups() {
    const questionGroups = this.orderedQuestionGroups();

    const sectionInfo = new docx.Paragraph({
      children: [
        this.newTextRun({ text: 'Law Firm Questions', size: 28, bold: true, break: 2, underline: { type: 'single', color: '000000', bold: true } })
      ]
    });

    const questionGroupParagraphs = questionGroups.flatMap((group, index) => {
      const elements = [];

      if (index === 0) {
        elements.push(sectionInfo);
      }

      elements.push(
        new docx.Paragraph({
          children: [
            this.newTextRun({ text: group.name, size: 22, bold: true, break: index === 0 ? 1 : 1.5, underline: { type: 'single', color: '000000' } }),
            ...this.questions(group)
          ]
        })
      );

      return elements;
    });

    return [...questionGroupParagraphs];
  }

  buildRecipients() {
    const children = [];

    const sectionInfo = new docx.Paragraph({
      children: [
        this.newTextRun({ text: 'Review & Send', size: 28, bold: true, break: 2, underline: { type: 'single', color: '000000', bold: true } })
      ]
    });

    children.push(sectionInfo);

    if (this.rfq.lawFirmQuotes.length > 0) {
      sectionInfo.addChildElement(this.newTextRun({ text: 'Your Firms', size: 24, bold: true, break: 2 }));

      this.rfq.lawFirmQuotes.forEach((quote) => {
        const lawFirmName = new docx.Paragraph({
          children: [
            this.newTextRun({ text: quote.firmName, size: 20, bold: true, italics: true })
          ]
        });

        children.push(lawFirmName);

        let rowData = [];

        if (quote.lawyersToReceiveUpdates.length > 0) {
          const recipients = this.recipientData(quote.lawyersToReceiveUpdates);

          rowData = rowData.concat(recipients);
        }

        if (quote.additionalRecipients.length > 0) {
          const recipients = this.recipientData(quote.additionalRecipients, false);

          rowData = rowData.concat(recipients);
        }

        const rows = this.buildRows(rowData);
        const recipientsTable = this.table(rows);

        children.push(recipientsTable);
      });
    }

    if (this.rfq.sentToMarketplace) {
      const marketplaceInfo = new docx.Paragraph({
        children: [
          this.newTextRun({ text: 'Priori\'s Talent Marketplace', size: 24, break: 2, bold: true }),
          this.newTextRun({ text: 'Included in proposal', italics: true, break: 1.5 })
        ]
      });

      children.push(marketplaceInfo);
    }

    return children;
  }

  practiceGroupText() {
    if (this.rfq.practiceGroups.length === 0) {
      return this.noResponseText();
    }

    return this.newTextRun({ text: this.rfq.practiceGroups.map(pg => pg.name).join(', '), break: 1.5 });
  }

  budgetText() {
    const { budgetMin, budgetMax } = this.rfq;

    if (!budgetMin && !budgetMax) {
      return this.noResponseText();
    }

    if (budgetMin && !budgetMax) {
      return this.newTextRun({ text: `$${budgetMin}`, break: 1.5 });
    }

    if (!budgetMin && budgetMax) {
      return this.newTextRun({ text: `$${budgetMax}`, break: 1.5 });
    }

    return this.newTextRun({ text: `$${budgetMin} - $${budgetMax}`, break: 1.5 });
  }

  questions(group) {
    const questions = this.orderedQuestions(group);

    const questionTextRuns = questions.flatMap(question => this.createQuestionTextRun(question));

    return questionTextRuns;
  }

  createQuestionTextRun(question) {
    const questionText = `${question.questionText}${question.isRequired ? ' *' : ''}`;

    return [
      this.newTextRun({ text: questionText, bold: true, break: 2 }),
      this.newTextRun({ text: 'Pending Firm Response', italics: true, break: 1.5 })
    ];
  }

  orderedQuestionGroups() {
    const groupsWithQuestions = this.rfq.questionGroups.filter(
      group => group.questions.some(question => question.isIncluded && !question.prerequisiteQuestionId)
    );

    return orderBy(groupsWithQuestions, 'position');
  }

  orderedQuestions(group) {
    const includedQuestions = group.questions.filter(question => question.isIncluded && !question.prerequisiteQuestionId);

    return orderBy(includedQuestions, 'position');
  }

  textRunFor(attribute) {
    const attr = this.rfq[attribute];

    return attr ? this.newTextRun({ text: attr, break: 1.5 }) : this.noResponseText();
  }

  noResponseText(text = null) {
    return this.newTextRun({ text: text ? text : 'No response', italics: true, break: 1.5 });
  }

  documentLinks(documents) {
    if (documents.length === 0) {
      return [this.noResponseText('No files attached')];
    }

    return documents.map(doc => {
      return new docx.ExternalHyperlink({
        link: doc.url,
        children: [this.newTextRun({ text: `• ${doc.name}`, style: 'Hyperlink', break: 1.5 })]
      });
    });
  }

  newTextRun(attrs = {}) {
    return new docx.TextRun({
      break: attrs.break || 1,
      font: 'Helvetica',
      ...attrs
    });
  }

  buildRows(rowData) {
    return rowData.map((row) => {
      return new docx.TableRow({
        children: [
          new docx.TableCell({
            children: [
              new docx.Paragraph({
                children: row.columnOneData,
                alignment: docx.AlignmentType.CENTER
              })
            ],
            width: {
              size: 25,
              type: docx.WidthType.PERCENTAGE
            },
            margins: {
              top: docx.convertInchesToTwip(0.1),
              bottom: docx.convertInchesToTwip(0.1),
              left: docx.convertInchesToTwip(0.1),
              right: docx.convertInchesToTwip(0.1)
            },
            verticalAlign: docx.VerticalAlign.CENTER
          }),
          new docx.TableCell({
            children: [
              new docx.Paragraph({
                children: row.columnTwoData,
                alignment: docx.AlignmentType.CENTER
              })
            ],
            width: {
              size: 50,
              type: docx.WidthType.PERCENTAGE
            },
            margins: {
              top: docx.convertInchesToTwip(0.1),
              bottom: docx.convertInchesToTwip(0.1),
              left: docx.convertInchesToTwip(0.1),
              right: docx.convertInchesToTwip(0.1)
            },
            verticalAlign: docx.VerticalAlign.CENTER
          }),
          new docx.TableCell({
            children: [
              new docx.Paragraph({
                children: row.columnThreeData,
                alignment: docx.AlignmentType.CENTER
              })
            ],
            width: {
              size: 25,
              type: docx.WidthType.PERCENTAGE
            },
            margins: {
              top: docx.convertInchesToTwip(0.1),
              bottom: docx.convertInchesToTwip(0.1),
              left: docx.convertInchesToTwip(0.1),
              right: docx.convertInchesToTwip(0.1)
            },
            verticalAlign: docx.VerticalAlign.CENTER
          })
        ]
      });
    });
  }

  lawyerNameAndTitle(lawyer) {
    const suggestedLawyer = new RfqSuggestedLawyer(lawyer);

    let name = [this.newTextRun({ text: suggestedLawyer.fullName(), size: 18, break: 0 })];

    if (suggestedLawyer.firmTitle()) {
      name.push(this.newTextRun({ text: suggestedLawyer.firmTitle(), break: 0.5, italics: true, size: 14 }));
    }

    return name;
  }

  formattedRate(lawyer) {
    return lawyer?.rate ? `${lawyer.rate}/hr` : '—';
  }

  tableTextRun(text, italics = false) {
    return [this.newTextRun({ text: text, size: 18, italics: italics, break: 0 })];
  }

  suggestedLawyerRowData(suggestedLawyers) {
    return suggestedLawyers.map((lawyer) => {
      return {
        columnOneData:   this.lawyerNameAndTitle(lawyer),
        columnTwoData:   this.tableTextRun('Suggested', true),
        columnThreeData: this.tableTextRun(this.formattedRate(lawyer.scoutLawyerBridge))
      };
    });
  }

  recipientData(lawyers, provisioned = true) {
    return lawyers.map((lawyer) => {
      return {
        columnOneData:   this.tableTextRun(lawyer.name),
        columnTwoData:   this.tableTextRun(lawyer.email),
        columnThreeData: this.tableTextRun(provisioned ? 'Provisioned Recipient' : 'Additional Recipient')
      };
    });
  }
}

export default RfqToDocx;
