<template>
  <div>
    <div v-if="!isFirmAdmin" :class="['reminders-widget', { 'no-action': !reminderPath }]">
      <div class="text-center bottom-30">
        <svg-icon name="exclamation-white" class="base-icon"></svg-icon>
      </div>

      <div class="reminder-content">
        <div class="reminder-text size-text-24px">
          {{ reminderText }}
        </div>
      </div>

      <div class="reminder-btn-container" v-if="reminderPath">
        <div class="reminder-btn">
          <a :href="reminderPath" class="nv-button-white no-border smaller" v-if="!reminderLinkTarget">{{ reminderLabel }}</a>
          <a :href="reminderPath" class="nv-button-white no-border smaller" target="_self" v-if="reminderLinkTarget">{{ reminderLabel }}</a>
        </div>
      </div>
    </div>

    <div v-if="isFirmAdmin" class="reminders-widget">
      <div class="text-center size-text-26px bottom-40">
        Welcome {{ lawyer.firstName }}.
      </div>

      <div class="reminder-content">
        <div class="reminder-text size-text-16px">
          View and update your {{ lawyer.alspAccount ? 'company' : 'firm' }} profile at any time.
        </div>
      </div>

      <div class="reminder-btn-container">
        <div class="reminder-btn">
          <a href="/lawyer-app/company" class="nv-button smaller" v-if="lawyer.alspAccount">VIEW COMPANY PROFILE</a>
          <a href="/lawyer-app/firm" class="nv-button smaller" v-else>VIEW FIRM PROFILE</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'LawyerReminders',

  components: {
    SvgIcon
  },

  props: {
    lawyer: {
      type: Object,
      default: () => ({})
    },

    lawyerReminder: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    isFirmAdmin() {
      return this.lawyer.isFirmAdmin();
    },

    reminderPath() {
      return this.lawyerReminder.path;
    },

    reminderText() {
      return this.lawyerReminder.text;
    },

    reminderLinkTarget() {
      return this.lawyerReminder.linkTarget;
    },

    reminderLabel() {
      return this.lawyerReminder.label;
    }
  }
};
</script>
