<template>
  <boxed-content title="Representative Matters" v-show="displayComponent()">
    <template #links v-if="canEditProfile">
      <edit-modal title="Editing Representative Matters" :input="matters" :on-save="save" :on-cancel="initMattersToDestroy">
        <template #default="{ inputCopy: mattersCopy }">
          <div class="small-bold-header">
            Representative Matters
          </div>

          <hr class="top-5">

          <div v-for="matter in mattersCopy" :key="matter.id" class="field-group bottom-20">
            <div class="bottom-20">
              <text-input-vertical
                label="Title"
                :id="`title-${matter.id}`"
                v-model="matter.name"
                :rules="{ requiresOneOf: { record: matter, fields: ['name', 'description'] }}">
              </text-input-vertical>
            </div>

            <div class="bottom-20">
              <text-input-vertical
                label="Client"
                :id="`client-${matter.id}`"
                v-model="matter.clientName">
              </text-input-vertical>
            </div>

            <div class="bottom-15">
              <validation-provider :rules="{ requiresOneOf: { record: matter, fields: ['name', 'description'] }}" v-slot="{ errors }" :mode="passiveAggressive">
                <label :for="`description-${matter.id}`">Description</label>

                <tiptap-editor
                  :id="`description-${matter.id}`"
                  v-model="matter.description">
                </tiptap-editor>

                <div class="error-text top-5" v-if="errors.length">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>

            <div>
              <a href="" class="remove-btn" @click.prevent="removeMatter(mattersCopy, matter)"><svg-icon name="minus-circle" class="base-icon right-10"></svg-icon>Remove</a>
            </div>
          </div>

          <div class="bottom-30">
            <a href="" class="semibold-weight" @click.prevent="addMatter(mattersCopy)">+ {{ mattersCopy.length ? 'Add Another' : 'Add Representative Matter' }}</a>
          </div>
        </template>

        <template #errorMessage>
          * One or more required fields are empty. Please fill in the required fields indicated above or remove that entry from the form.
        </template>
      </edit-modal>
    </template>

    <loading-section name="representative-matters">
      <display-rules-data-wrapper :data="matters">
        <div v-for="(matter, index) in displayedMatters" :key="matter.id">
          <div class="size-text-16px semibold-weight">
            {{ matter.name }}
          </div>

          <div class="dark-gray-text semibold-weight top-2" v-if="hasClientName(matter)">
            {{ matter.clientName }}
          </div>

          <div class="top-15" v-if="hasDescription(matter)">
            <rich-text-display :content="matter.sanitizedDescription"></rich-text-display>
          </div>

          <hr class="kenny-hr" v-if="!isLastMatter(index)">
        </div>

        <div class="top-20" v-if="!showAll && matters.length > 3">
          <a href="" class="semibold-weight size-text-13px" @click.prevent="viewAll">Show All</a>
        </div>
      </display-rules-data-wrapper>
    </loading-section>
  </boxed-content>
</template>

<script>
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import DisplayRulesDataWrapper from 'vue-app/shared/components/display-rules/display-rules-data-wrapper.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import BoxedContent from 'vue-app/shared/components/boxed-content.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import TiptapEditor from 'vue-app/scout/shared/tiptap-editor.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import { ValidationProvider } from 'vee-validate';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import RichTextDisplay from 'vue-app/shared/components/rich-text-display.vue';
import DataLock from 'resources/data-lock.js';

export default {
  name: 'FirmRepresentativeMatters',

  components: {
    BoxedContent,
    EditModal,
    TextInputVertical,
    DisplayRulesDataWrapper,
    TiptapEditor,
    ValidationProvider,
    RichTextDisplay,
    LoadingSection,
    SvgIcon
  },

  mixins: [
    displayRules,
    interactionModes
  ],

  props: {
    matters: {
      type:    Array,
      default: () => []
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type:    Function,
      default: () => {}
    },

    firmId: {
      type:    Number,
      default: null
    }
  },

  data() {
    return {
      showAll: false,
      mattersToDestroy: []
    };
  },

  computed: {
    displayedMatters() {
      if (this.showAll) {
        return this.matters;
      }
      else {
        return this.matters.slice(0, 3);
      }
    }
  },

  methods: {
    displayComponent() {
      return this.anyDataIsPresent([this.matters]) || this.canEditProfile;
    },

    hasClientName(matter) {
      return matter.clientName && matter.clientName.length;
    },

    hasDescription(matter) {
      return matter.description && matter.description.length;
    },

    isLastMatter(index) {
      return index === this.displayedMatters.length - 1;
    },

    viewAll() {
      this.showAll = true;
    },

    addMatter(mattersCopy) {
      mattersCopy.push({
        name: '',
        clientName: '',
        description: ''
      });
    },

    initMattersToDestroy() {
      this.mattersToDestroy = [];
    },

    removeMatter(mattersCopy, matter) {
      const index = mattersCopy.indexOf(matter);

      if (index > -1 && matter.id) {
        this.mattersToDestroy.push({ id: matter.id, '_destroy': true });
        mattersCopy.splice(index, 1);
      }
      else if (index > -1) {
        mattersCopy.splice(index, 1);
      }
    },

    save(editedMatters) {
      const vueInstance = this;

      if (vueInstance.canEditProfile) {
        LoadingService.loading('representative-matters');

        vueInstance.onSave({ lawFirmRepresentativeMattersAttributes: [...editedMatters, ...vueInstance.mattersToDestroy] },
          () => {
            DataLock.save({
              dataLock: {
                flag:         ['representative_matters'],
                resourceId:   this.firmId,
                resourceType: 'law_firm'
              }
            });
            vueInstance.mattersToDestroy = [];
            LoadingService.done('representative-matters');
          },
          () => {
            LoadingService.done('representative-matters');
          }
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "../../../../stylesheets/scout/variables";

  .remove-btn {
    align-items: center;
    color: $k-darker-gray;
    display: flex;
    justify-content: right;
  }
</style>
