<template>
  <boxed-content v-show="displayComponent()" title="About">
    <template #links v-if="canEditProfile">
      <edit-modal title="Editing About" :input="firm" :on-save="save">
        <template #default="{ inputCopy: editedFirm }">
          <tiptap-editor v-model="editedFirm.description" class="bottom-30"></tiptap-editor>
        </template>
      </edit-modal>
    </template>

    <display-rules-section-wrapper :data="firm.sanitizedDescription" :editing-allowed="canEditProfile">
      <loading-section name="firm-about">
        <display-rules-data-wrapper :data="firm.sanitizedDescription">
          <div v-if="showSubBio">
            <span class="bottom-10" v-html="`${subBio}...`"></span>
            <a href="" class="size-text-12px semibold-weight" @click.prevent="expandDescription">View more</a>
          </div>

          <div v-if="showFullBio" class="list-two-levels">
            <span v-html="sanitizedBio"></span>
          </div>
        </display-rules-data-wrapper>
      </loading-section>
    </display-rules-section-wrapper>
  </boxed-content>
</template>

<script>
import EditModal from 'vue-app/scout/shared/edit-modal.vue';
import LoadingService from 'vue-app/shared/services/loading-service';
import TiptapEditor from 'vue-app/scout/shared/tiptap-editor.vue';
import displayRules from 'vue-app/shared/mixins/display-rules.js';
import DisplayRulesSectionWrapper from 'vue-app/shared/components/display-rules/display-rules-section-wrapper.vue';
import DisplayRulesDataWrapper from 'vue-app/shared/components/display-rules/display-rules-data-wrapper.vue';
import BoxedContent from 'vue-app/shared/components/boxed-content.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import DataLock from 'resources/data-lock.js';

export default {
  name: 'FirmAbout',

  components: {
    EditModal,
    TiptapEditor,
    BoxedContent,
    DisplayRulesSectionWrapper,
    DisplayRulesDataWrapper,
    LoadingSection
  },

  mixins: [displayRules],

  props: {
    firm: {
      type: Object,
      required: true
    },

    canEditProfile: {
      type: Boolean,
      default: false
    },

    onSave: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      maxLength: 750,
      showFullDescription: false
    };
  },

  computed: {
    sanitizedBio() {
      return this.firm.sanitizedDescription || '';
    },

    bioLength() {
      return this.sanitizedBio?.length || 0;
    },

    subBio() {
      let subBio = this.sanitizedBio.substring(0, this.maxLength);
      return subBio.substr(0, Math.min(subBio.length, subBio.lastIndexOf(' ')));
    },

    showFullBio() {
      return this.showFullDescription || (this.bioLength <= this.maxLength);
    },

    showSubBio() {
      return !this.showFullDescription && (this.bioLength > this.maxLength);
    }
  },

  methods: {
    displayComponent() {
      return this.anyDataIsPresent([this.firm.sanitizedDescription]) || this.canEditProfile;
    },

    expandDescription() {
      this.showFullDescription = true;
    },

    save(editedFirm) {
      if (!this.canEditProfile) { return; }

      LoadingService.loading('firm-about');

      this.onSave(editedFirm, () => {
        DataLock.save({
          dataLock: {
            flag:         ['about'],
            resourceId:   editedFirm.id,
            resourceType: 'law_firm'
          }
        });
        LoadingService.done('firm-about');
      });
    }
  }
};
</script>

<style lang="scss" scoped>
  :deep(ul) {
    padding-inline-start: 16px;
  }

  :deep(ol) {
    padding-inline-start: 13px;
  }
</style>
