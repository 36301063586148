<template>
  <div class="row tight-columns vertical-center-not-xs">
    <div class="col-sm-8">
      <lawyer-name-plate
        :lawyer="lawyerBridge"
        photo-size="small"
        :compact="true"
        :show-favorite="false"
        :show-lawyer-link="true">
      </lawyer-name-plate>
    </div>

    <div class="col-sm-4 text-right-not-xs" v-if="!isDeactivatedLawyer">
      <div class="semibold-weight" v-if="showRateTitle">
        Hourly Rate
      </div>

      <div>
        {{ rateDisplay }}
      </div>
    </div>
  </div>
</template>

<script>
import LawyerNamePlate from 'vue-app/scout/shared/lawyer-name-plate.vue';
import ScoutLawyerBridge from 'resources/scout/scout-lawyer-bridge.js';

export default {
  name: 'RfqSuggestedLawyer',

  components: {
    LawyerNamePlate
  },

  props: {
    lawyer: {
      type: Object,
      required: true
    },

    showRateTitle: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    lawyerBridge() {
      return new ScoutLawyerBridge(this.lawyer);
    },

    isDeactivatedLawyer() {
      return this.lawyerBridge.isDeactivated();
    },

    rateDisplay() {
      return this.lawyer?.rate ? `${this.lawyer.rate}/hr` : '—';
    }
  }
};
</script>
