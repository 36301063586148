<template>
  <div class="item-wrapper">
    <div class="d-flex justify-between items-center">
      <div class="bold-weight size-text-15px">
        {{ rfqLawFirmQuote.firmName }}
      </div>

      <div v-if="!isFeatureLab">
        <button type="button" class="nv-button-square-white" @click="toggleSuggestedLawyers"><svg-icon name="caret-right" :class="['base-icon disclosure-icon', { 'expanded rotate-90': isExpanded }]"></svg-icon></button>
      </div>

      <div v-else class="top-10 bottom-10">
        <rfq-remove-firm :rfq="rfq" :rfq-law-firm-quote="rfqLawFirmQuote"></rfq-remove-firm>
      </div>
    </div>

    <div class="top-20" v-if="isExpanded">
      <div v-if="hasLawyers">
        <rfq-recipient-lawyer-item v-for="lawyer in sortedLawyerBridges" :key="lawyer.id" class="list-spacing-10" :lawyer="lawyer" :rfq-law-firm-quote="rfqLawFirmQuote"></rfq-recipient-lawyer-item>
      </div>

      <div v-else class="empty-list">
        You have not suggested any lawyers from this firm.
      </div>

      <div class="d-flex top-20 justify-between">
        <div class="right-10">
          <rfq-add-lawyer :rfq-law-firm-quote="rfqLawFirmQuote"></rfq-add-lawyer>
        </div>

        <div>
          <rfq-remove-firm :rfq="rfq" :rfq-law-firm-quote="rfqLawFirmQuote"></rfq-remove-firm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RfqRecipientLawyerItem from 'vue-app/scout/client/rfqs/create/recipient-lawyer-item.vue';
import RfqAddLawyer from 'vue-app/scout/client/rfqs/create/add-lawyer.vue';
import RfqRemoveFirm from 'vue-app/scout/client/rfqs/create/remove-firm.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';
import { sortBy } from 'lodash';

export default {
  name: 'RfqRecipientItem',

  components: {
    RfqRecipientLawyerItem,
    RfqAddLawyer,
    RfqRemoveFirm
  },

  props: {
    rfqLawFirmQuote: {
      type: Object,
      required: true
    },

    rfq: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isExpanded: false
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, ['currentProduct']),

    isFeatureLab() {
      return this.currentProduct.isFeatureLab;
    },

    hasLawyers() {
      return this.rfqLawFirmQuote.suggestedLawyers.length > 0;
    },

    sortedLawyerBridges() {
      return sortBy(this.rfqLawFirmQuote.suggestedLawyers, (item) => (item.scoutLawyerBridge.lastName || item.scoutLawyerBridge.fullName).toLowerCase());
    }
  },

  methods: {
    toggleSuggestedLawyers() {
      this.isExpanded = !this.isExpanded;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .empty-list {
    border-radius: $border-radius-large;
    background-color: $k-lighter-gray;
    color: $pill-gray;
    padding: 25px;
    text-align: center;
  }

  .item-wrapper {
    border: 1px solid $medium-gray;
    border-radius: $border-radius-large;
    padding: 12px 15px;
  }
</style>
