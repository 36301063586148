<template>
  <validation-provider :rules="validationRules" v-slot="{ errors }" :mode="passiveAggressive">
    <div class="row">
      <div class="col-sm-4">
        <label :class="['semibold-weight', { 'no-margin': !editMode }]">{{ label }} <span v-if="required" class="inline-help required">*</span></label>

        <div class="dark-gray-text size-text-12px" v-if="subText">
          {{ subText }}
        </div>

        <div class="error-text top-5" v-if="editMode && errors.length && showErrorMessage">
          {{ errors[0] }}
        </div>
      </div>

      <div class="col-sm-8">
        <span v-if="!editMode" class="simple-text" v-html="currentValue || '&mdash;'"></span>

        <div v-show="editMode" class="top-10-xs">
          <tiptap-editor
            :value="currentValue"
            :buttons="['bold', 'italic', 'underline', 'bulletList','orderedList', 'strike', 'heading', 'quote']"
            :has-error="errors.length > 0"
            :placeholder="placeholder"
            @input="(value) => onInput(value)">
          </tiptap-editor>
        </div>
      </div>
    </div>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import TiptapEditor from 'vue-app/scout/shared/tiptap-editor.vue';
import interactionModes from 'vue-app/shared/mixins/interaction-modes';

export default {
  name: 'WysiwygHorizontal',

  components: {
    ValidationProvider,
    TiptapEditor
  },

  mixins: [
    interactionModes
  ],

  props: {
    label: {
      type: String,
      required: true
    },

    name: {
      type: String,
      required: false
    },

    subText: {
      type: String,
      default: null
    },

    placeholder: {
      type: String,
      default: 'Enter content'
    },

    currentValue: {
      type: String,
      default: ''
    },

    editMode: {
      type: Boolean,
      default: false
    },

    novalidate: {
      type: Boolean,
      default: false
    },

    required: {
      type: Boolean,
      default: false
    },

    showErrorMessage: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    validationRules() {
      return this.novalidate ? null : 'required|requiredHtml';
    }
  },

  methods: {
    onInput(value) {
      this.$emit('input', value);
    }
  }
};
</script>
