<template>
  <div>
    <div class="semibold-weight bottom-10">
      Rate Calculator
    </div>

    <inline-notification class="bottom-15" type="info">
      <span class="bold-weight">Note:</span> Use this space to check your calculations. This information will not be shared with the clients or lawyers.
    </inline-notification>

    <div class="row tight-columns">
      <div class="col-sm-4">
        <label :for="totalRateId">Priori Total Rate</label>

        <validation-provider rules="required|minValue:0.01" mode="eager" v-slot="{ errors, failed }" :custom-messages="{ minValue: '* Rate must be greater than 0' }">
          <div class="input-with-icon">
            <input
              type="number"
              :id="totalRateId"
              :class="['form-control', { 'has-error': failed }]"
              min="0.01"
              step="0.01"
              v-model="totalRate"
              @input="totalRateChanged">
            <div class="form-icon">$</div>

            <div class="error-text top-5" v-if="errors.length">
              <error-message :errors="errors"></error-message>
            </div>
          </div>
        </validation-provider>
      </div>

      <div class="col-sm-4 top-20-xs">
        <label :for="lawyerRateId">Lawyer Rate</label>

        <validation-provider rules="required|minValue:0.01" mode="eager" v-slot="{ errors, failed }" :custom-messages="{ minValue: '* Rate must be greater than 0' }">
          <div class="input-with-icon">
            <input
              type="number"
              :id="lawyerRateId"
              :class="['form-control', { 'has-error': failed }]"
              min="0.01"
              step="0.01"
              v-model="lawyerRate"
              @input="lawyerRateChanged">
            <div class="form-icon">$</div>

            <div class="error-text top-5" v-if="errors.length">
              <error-message :errors="errors"></error-message>
            </div>
          </div>
        </validation-provider>
      </div>

      <div class="col-sm-4 top-20-xs">
        <label :for="prioriFeeId"><div class="text-ellipsis">Priori Management Fee</div></label>

        <div class="input-with-icon">
          <input
            type="number"
            :id="prioriFeeId"
            class="form-control"
            disabled="true"
            v-model="managementFee">
          <div class="form-icon">$</div>
        </div>

        <div class="top-5">
          Management Fee: <span class="semibold-weight">{{ managementFeePercent }}%</span>
        </div>

        <div>
          <a href="" @click.prevent="editFee">Edit</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InlineNotification from 'vue-app/shared/components/inline-notification.vue';
import ErrorMessage from 'vue-app/shared/components/error-message.vue';
import { ValidationProvider } from 'vee-validate';
import { round, uniqueId } from 'lodash';

export default {
  name: 'ProposalRateCalculator',

  components: {
    InlineNotification,
    ErrorMessage,
    ValidationProvider
  },

  props: {
    possibleLawyer: {
      type: Object,
      required: true
    },

    counselRequest: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      totalRate: 0,
      lawyerRate: 0
    };
  },

  computed: {
    managementFeePercent() {
      return this.counselRequest.managementFeePercent;
    },

    managementFee() {
      return round(this.totalRate - this.lawyerRate, 2);
    },

    totalRateId() {
      return uniqueId('total-rate-');
    },

    lawyerRateId() {
      return uniqueId('lawyer-rate-');
    },

    prioriFeeId() {
      return uniqueId('priori-fee-');
    }
  },

  watch: {
    'counselRequest.managementFeePercent': function () {
      this.clearInputs();
    }
  },

  methods: {
    clearInputs() {
      this.totalRate = 0;
      this.lawyerRate = 0;
    },

    totalRateChanged() {
      this.lawyerRate = round(this.totalRate / (1 + (this.managementFeePercent / 100)), 2);
    },

    lawyerRateChanged() {
      this.totalRate = round(this.lawyerRate * (1 + (this.managementFeePercent / 100)), 2);
    },

    editFee() {
      this.clearInputs();

      document.getElementById('request-link').click();
      document.getElementById('billing-info').scrollIntoView({ block: 'start', behavior: 'smooth' });
      document.getElementById('billing-info-edit').click();
      document.getElementById('management-fee-percent').select();
    }
  }
};
</script>
