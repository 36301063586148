<template>
  <div>
    <div class="titlebar reviews-titlebar">
      <div class="row tight-columns vertical-center-not-xs">
        <div :class="[{ 'col-sm-5 col-md-6 col-lg-7': reviewerIsCurrentClient, 'col-sm-8 col-md-9': !reviewerIsCurrentClient }]">
          <div class="medium-page-heading">
            Review Detail
          </div>
        </div>

        <div class="col-sm-4 col-md-3 top-10-xs">
          <a :href="`scout-company/law-firms/${firm.id}/reviews/new`" class="secondary-btn-blue" target="_self">Leave A New Review</a>
        </div>

        <div class="col-sm-3 col-lg-2 top-10-xs" v-if="reviewerIsCurrentClient">
          <a :href="`scout-company/reviews/${review.id}/edit`" class="secondary-btn-blue" target="_self">Edit Review</a>
        </div>
      </div>
    </div>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container review-container">
          <div class="bottom-30" v-if="hasReviewsNote">
            <interface-note :note-text="reviewsNote"></interface-note>
          </div>

          <div class="row">
            <div class="col-sm-4">
              <firm-card :firm="firm"></firm-card>
            </div>

            <div class="col-sm-8 top-30-xs">
              <review-details :review="review" :review-template="template"></review-details>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InterfaceNote from 'vue-app/shared/components/interface-note.vue';
import FirmCard from 'vue-app/scout/client/reviews/firm-card.vue';
import ReviewDetails from 'vue-app/scout/client/reviews/review-details.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'ShowFirmReview',

  components: {
    InterfaceNote,
    FirmCard,
    ReviewDetails
  },

  props: {
    firm: {
      type:     Object,
      required: true
    },

    review: {
      type:     Object,
      required: true
    },

    template: {
      type:     Object,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),

    reviewerIsCurrentClient() {
      return this.review.reviewerId === this.currentClient.id;
    },

    reviewsNote() {
      return this.currentClient.workspace.scoutReviewsNote;
    },

    hasReviewsNote() {
      return this.reviewsNote && this.reviewsNote.length > 0;
    }
  }
};
</script>
