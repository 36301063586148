<template>
  <div class="clear-panel compact-panel">
    <div class="notification-row">
      <div class="row tight-columns bold-weight bottom-10">
        <div class="col-sm-9 text-ellipsis">
          RFP #{{ rfqId }}<span v-if="rfqName"> – {{ rfqName }}</span>
        </div>

        <div class="col-sm-3 text-right-not-xs">
          <a :href="notificationLink" class="bold-weight">View RFP</a>
        </div>
      </div>

      <div v-for="(notification, index) in notifications" :key="index" class="pill-gray-text left-15">
        <hr v-if="index > 0" class="margin-10">

        <div :class="['notification-icon inline-block right-10', iconNameMap[notification.type]]">
          <svg-icon :name="iconNameMap[notification.type]" class="base-icon"></svg-icon>
        </div>

        {{ notification.message }}
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import { some } from 'lodash';

export default {
  name: 'RfqNotification',

  components: {
    SvgIcon
  },

  props: {
    rfqName: {
      type: String,
      default: null
    },

    notifications: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      iconNameMap: {
        'action_required': 'notification',
        'new_messages': 'envelope'
      }
    };
  },

  computed: {
    rfqId() {
      return this.notifications[0].rfqId;
    },

    isDraft() {
      return some(this.notifications, { isDraft: true });
    },

    notificationLink() {
      return this.isDraft ? `/scout-company/rfps/${this.rfqId}/edit` : `/scout-company/rfps/${this.rfqId}`;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .notification-row {
    .notification-icon {
      position: relative;
      width: 22px;

      .base-icon {
        width: 20px;
        height: 20px;
        fill: $k-dark-gray;
      }

      &::after {
        display: block;
        content: '';
        width: 8px;
        height: 8px;
        position: absolute;
        top: 1px;
        right: 1px;
        background-color: $scout-red;
        border-radius: 50%;
      }

      &.envelope::after {
        right: -2px;
      }
    }
  }

  hr.margin-10 {
    margin: 10px 0;
    border-color: $k-light-gray;
  }
</style>
