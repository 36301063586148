<template>
  <priori-modal ref="searchSelectModal" modal-id="rfq-from-saved-searches-modal" title="Populate RFP from Saved Search" @on-modal-close="resetSelectionModal">
    <template #modal-trigger="{ openModal }">
      <button type="button" class="secondary-button-compact" @click="openModal">Use Saved Search</button>
    </template>

    <form role="form" class="a-form" novalidate>
      <div class="bottom-30">
        <loading-section name="savedSearches">
          <dropdown-select
            ref="listDropdown"
            label="Select one of your Saved Searches to populate the RFP"
            placeholder="Select One"
            id-label="rfq-existing-search"
            :options="savedSearches"
            value-key="id"
            @input="(searchId) => selectedSearchId = searchId"
            label-key="name">
          </dropdown-select>
        </loading-section>
      </div>

      <div class="row tight-columns">
        <div class="col-sm-4">
          <button type="button" :class="['primary-btn-blue', { 'disabled': !selectedSearchId }]" @click="proceedToSelectSearch">Select Search</button>
        </div>

        <div class="col-sm-3 top-20-xs">
          <button type="button" class="nv-button-white" @click="resetSelectionModal">Cancel</button>
        </div>
      </div>
    </form>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import ScoutSearch from 'resources/scout/scout-search.js';
import RFQLawFirmQuote from 'resources/scout/rfq-law-firm-quote.js';
import { partition } from 'lodash';

export default {
  name: 'AddFromSavedSearch',

  components: {
    PrioriModal,
    LoadingSection,
    DropdownSelect
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    savedSearches: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      selectedSearchId: null
    };
  },

  methods: {
    proceedToSelectSearch() {
      ScoutSearch.search({ id: this.selectedSearchId, full_results: true }).then((search) => {
        const searchType = JSON.parse(search.searchParams).type;
        const resourceIds = search.results.map(result => result.id);
        const params = { resource_ids: resourceIds, search_type: searchType };

        RFQLawFirmQuote.getFromSavedSearch(params).then((quotes) => {
          const [rfqEnabledQuotes, rfqDisabledQuotes] = partition(quotes.data, 'firmHasRfqEnabled');

          this.rfq.lawFirmQuotes = rfqEnabledQuotes;
          this.rfq.disabledLawFirmQuotes = rfqDisabledQuotes;
        });
      });
    },

    resetSelectionModal() {
      this.selectedSearchId = null;
      this.$refs.searchSelectModal.closeModal();
      this.$refs.listDropdown.reset();
    }
  }
};
</script>
