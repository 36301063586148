<template>
  <div>
    <div class="row tight-columns">
      <div class="col-sm-8 dash-project-container" @click="viewProject">
        <div class="dash-project-name text-ellipsis">
          {{ matter.client.businessName || matter.client.fullName }}
        </div>

        <div class="text-ellipsis">
          <span class="semibold-weight purple-text right-5">{{ matter.nameForLawyersReference }}</span>
          <span class="right-5">&#8231;</span>
          <span>Contact: <span class="semibold-weight">{{ matter.client.fullName }}</span></span>

          <template v-if="matter.wework">
            <span class="left-5 right-5">&#8231;</span>
            <span class="purple-text semibold-weight">via WeWork</span>
          </template>
        </div>
      </div>

      <div class="col-sm-4 top-4">
        <div class="row tight-columns text-right-not-xs">
          <div class="col-xs-9 col-lg-8">
            <a :href="`/lawyer-app/projects/${matter.id}`" class="nv-button-white smaller">View Project</a>
          </div>

          <div class="col-xs-3 col-lg-4">
            <dropdown-menu>
              <li class="menu-item" role="menuitem">
                <a href="" @click.prevent="messageClient"><svg-icon name="envelope" class="base-icon"></svg-icon>Message Client</a>
              </li>

              <li class="menu-item" role="menuitem">
                <project-status-modal
                  :matter="matter"
                  :toggle-close-project="toggleCloseProject">
                </project-status-modal>
              </li>
            </dropdown-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectStatusModal from 'vue-app/marketplace/lawyer/matters/project-status-modal.vue';
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'RecentProject',

  components: {
    ProjectStatusModal,
    DropdownMenu,
    SvgIcon
  },

  props: {
    matter: {
      type: Object,
      required: true
    },

    sendMessageService: {
      type: Object,
      required: true
    },

    closeProject: {
      type: Function,
      required: true
    }
  },

  methods: {
    toggleCloseProject() {
      this.closeProject(this.matter);
    },

    messageClient() {
      this.sendMessageService.modal([this.matter.client]);
    },

    viewProject() {
      window.location.href = `/lawyer-app/projects/${this.matter.id}`;
    }
  }
};
</script>
