<template>
  <div>
    <interface-note note-text="Note: The list of law firms enabled to receive RFPs can be viewed on the Law Firms page. The list of individual recipients provisioned by each firm to receive this RFP is visible in Step 4. There you can also send notifications to additional parties at each firm."></interface-note>
    <div class="shadowed-box top-20">
      <div class="box-content extra-padded">
        <div class="bottom-20">
          <div class="size-text-17px bold-weight">
            Select RFP Recipients
          </div>

          <div class="top-2" v-if="isFeatureLab || marketplaceRfqsEnabled">
            Use the toggles below to request proposals from your law firms, Priori’s Network, or both.
          </div>
        </div>

        <!-- Your Firms -->
        <div class="item-wrapper toggle-section">
          <toggle-button
            v-if="isFeatureLab || marketplaceRfqsEnabled"
            :id="'toggle-firms'"
            :has-text="false"
            :value="firmsEnabled"
            :title="'Your Firms'"
            @click="toggleFirms">
          </toggle-button>

          <div v-else class="bold-weight size-text-15px">
            Your Firms
          </div>

          <hr>

          <div :class="['bottom-10', { 'pill-gray-text': !firmsEnabled }]">
            Select which law firms should receive this RFP and suggest lawyers at those firms that might be a good fit for the project.
          </div>

          <loading-section v-if="firmsEnabled" name="updateSelectedRfqFirms">
            <div v-if="hasSelectedRecipients" class="bottom-30 top-30">
              <rfq-recipient-item
                v-for="rfqRecipient in sortedLawFirmQuotes"
                class="list-spacing-20"
                :key="rfqRecipient.id"
                :rfq-law-firm-quote="rfqRecipient"
                :rfq="rfq">
              </rfq-recipient-item>

              <rfq-disabled-recipient-item
                v-for="rfqRecipient in sortedDisabledLawFirmQuotes"
                class="list-spacing-20"
                :key="rfqRecipient.id"
                :rfq-law-firm-quote="rfqRecipient"
                :rfq="rfq"
                @contact-admins="onContactAdmins">
              </rfq-disabled-recipient-item>
            </div>

            <div v-else class="padded-container text-center empty-list top-30 bottom-30">
              You have not added any law firms yet
            </div>
          </loading-section>

          <div v-if="firmsEnabled" class="vertical-center-not-xs">
            <div class="right-15">
              <rfq-add-firm :rfq="rfq" :available-firms="getAvailableFirms"></rfq-add-firm>
            </div>

            <div class="pill-gray-text right-15 top-10-xs" v-if="showListImport">
              or
            </div>

            <div :class="['top-10-xs', { 'right-15': showSavedSearchButton }]" v-if="showListImport">
              <add-from-list
                :rfq="rfq"
                :resource-lists="resourceLists">
              </add-from-list>
            </div>

            <div class="pill-gray-text right-15 top-10-xs" v-if="showSavedSearchButton">
              or
            </div>

            <div class="top-10-xs" v-if="showSavedSearchButton">
              <add-from-saved-search
                :rfq="rfq"
                :saved-searches="savedSearches">
              </add-from-saved-search>
            </div>
          </div>
        </div>

        <!-- Marketplace -->
        <div v-if="isFeatureLab || marketplaceRfqsEnabled" class="item-wrapper toggle-section top-20">
          <div class="row tight-columns vertical-center-not-xs top-40-xs">
            <div class="col-sm-9 col-lg-10">
              <toggle-button
                :id="'toggle-marketplace'"
                :value="rfq.sentToMarketplace"
                :has-text="false"
                :title="`Priori’s Marketplace Network`"
                :sub-title="'(Recommended)'"
                @click="toggleMarketplace">
              </toggle-button>
            </div>

            <div class="col-xs-7 col-sm-3 col-lg-2 vertical-center top-20-xs">
              <svg-icon name="marketplace-logo"></svg-icon>
            </div>
          </div>

          <hr>

          <div :class="{ 'pill-gray-text': !rfq.sentToMarketplace }">
            Priori Marketplace connects in-house legal teams with vetted lawyers and other legal providers for projects globally.
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-end top-30">
      <button type="button" class="bold-weight gap-10 pseudo-link-blue vertical-center blue-text" @click="next">Continue<svg-icon name="arrow-right" class="base-icon"></svg-icon></button>
    </div>

    <div v-if="stepInvalid && !stepIsValid" class="red-link text-right top-5">
      * Please complete all required fields
    </div>
  </div>
</template>

<script>
import RfqRecipientItem from 'vue-app/scout/client/rfqs/create/recipient-item.vue';
import RfqDisabledRecipientItem from 'vue-app/scout/client/rfqs/create/disabled-recipient-item.vue';
import RfqAddFirm from 'vue-app/scout/client/rfqs/create/add-firm.vue';
import AddFromList from 'vue-app/scout/client/rfqs/create/add-from-list.vue';
import AddFromSavedSearch from 'vue-app/scout/client/rfqs/create/add-from-saved-search.vue';
import ToggleButton from 'vue-app/shared/components/toggle-button.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import InterfaceNote from 'vue-app/shared/components/interface-note.vue';
import { sortBy } from 'lodash';

export default {
  name: 'RfqStepRecipients',

  components: {
    RfqRecipientItem,
    RfqDisabledRecipientItem,
    RfqAddFirm,
    AddFromList,
    AddFromSavedSearch,
    ToggleButton,
    SvgIcon,
    InterfaceNote
  },

  props: {
    nextStep: {
      type: Object
    },

    prevStep: {
      type: Object
    },

    rfq: {
      type: Object,
      required: true
    },

    onDestroyAllLawFirmQuotes: {
      type: Function,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    },

    isFeatureLab: {
      type: Boolean,
      required: true
    },

    marketplaceRfqsEnabled: {
      type: Boolean,
      required: true
    },

    savedSearches: {
      type: Array,
      required: true
    },

    resourceLists: {
      type: Array,
      required: true
    },

    availableFirms: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      firmsEnabled: this.initFirmsEnabled(),
      stepInvalid: false
    };
  },

  computed: {
    hasLawFirmQuotes() {
      return this.rfq.lawFirmQuotes !== undefined && this.rfq.lawFirmQuotes.length > 0;
    },

    stepIsValid() {
      if (!this.firmsEnabled && !this.rfq.sentToMarketplace) { return false; }

      let isValid = this.rfq.sentToMarketplace;

      if (this.firmsEnabled) {
        isValid = this.hasLawFirmQuotes && this.rfq.lawFirmQuotes.some(quote => quote.firmHasRfqEnabled);
      }

      return isValid;
    },

    hasSelectedRecipients() {
      return (this.hasLawFirmQuotes && this.rfq.lawFirmQuotes.length > 0) ||
        (this.rfq.disabledLawFirmQuotes && this.rfq.disabledLawFirmQuotes.length > 0)
      ;
    },

    sortedLawFirmQuotes() {
      return sortBy(this.rfq.lawFirmQuotes, (item) => item.firmName.toLowerCase());
    },

    sortedDisabledLawFirmQuotes() {
      return sortBy(this.rfq.disabledLawFirmQuotes, (item) => item.firmName.toLowerCase());
    },

    getAvailableFirms() {
      if (this.rfq.lawFirmQuotes && this.rfq.lawFirmQuotes.length < 1) {
        return this.availableFirms;
      }

      const firmIdsSet = new Set(this.rfq.lawFirmQuotes.map(item => item.lawFirmId));
      return this.availableFirms.filter(item => !firmIdsSet.has(item.id));
    },

    showListImport() {
      return !this.hasLawFirmQuotes && !this.rfq.hasImportedList && this.resourceLists.length !== 0;
    },

    showSavedSearchButton() {
      return !this.hasLawFirmQuotes && !this.rfq.hasImportedList && this.savedSearches.length !== 0;
    }
  },

  methods: {
    initFirmsEnabled() {
      if (this.rfq?.id === undefined || !this.isFeatureLab) {
        return true;
      }
      return this.rfq.lawFirmQuotes.length > 0;
    },

    next() {
      if (this.stepIsValid) {
        this.onSave({ rfq: this.rfq, step: 1 }).then((resp) => {
          if (!this.firmsEnabled) { this.onDestroyAllLawFirmQuotes(); }

          this.nextStep.moveToNextStep(resp);
        });
      }
      else {
        this.stepInvalid = true;
      }
    },

    onContactAdmins(data) {
      this.$emit('contact-admins', data);
    },

    toggleMarketplace() {
      this.rfq.sentToMarketplace = !this.rfq.sentToMarketplace;
    },

    toggleFirms() {
      this.firmsEnabled = !this.firmsEnabled;
      this.$emit('firms-enabled-changed', this.firmsEnabled);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .empty-list {
    border-radius: $border-radius-large;
    background-color: $k-lighter-gray;
    color: $pill-gray;
  }

  .item-wrapper {
    @media (min-width: $screen-sm-min) {
      border: 1px solid $k-light-gray;
      border-radius: $border-radius-large;
    }
  }

  .toggle-section {
    @media (min-width: $screen-sm-min) {
      padding: 20px;
    }

    @media (min-width: $screen-md-min) {
      padding: 30px;
    }
  }
</style>
