<template>
  <div class="row">
    <div class="col-sm-8">
      <data-disclaimer-box class="bottom-30" :firm-name="firm.name"></data-disclaimer-box>

      <firm-practice-areas class="bottom-30" :firm="firm"></firm-practice-areas>

      <firm-office-locations :firm="firm"></firm-office-locations>
    </div>

    <div class="col-sm-4 top-30-xs">
      <firm-status :firm="firm"></firm-status>
    </div>
  </div>
</template>

<script>
import FirmPracticeAreas from 'src/vue-app/scout/shared/law-firms/firm-practice-areas.vue';
import FirmOfficeLocations from 'src/vue-app/scout/shared/law-firms/firm-office-locations.vue';
import DataDisclaimerBox from 'vue-app/scout/shared/data-disclaimer-box.vue';
import FirmStatus from 'vue-app/scout/client/law-firms/firm-status.vue';

export default {
  name: 'GhostFirmDetails',

  components: {
    DataDisclaimerBox,
    FirmStatus,
    FirmPracticeAreas,
    FirmOfficeLocations
  },

  props: {
    firm: {
      type: Object,
      required: true
    }
  }
};
</script>
