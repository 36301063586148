<template>
  <div>
    <show-lawyer-review
      v-if="isLawyerReview"
      :lawyer="resource"
      :review="review"
      :template="reviewTemplate">
    </show-lawyer-review>

    <show-firm-review
      v-else
      :firm="resource"
      :review="review"
      :template="reviewTemplate">
    </show-firm-review>
  </div>
</template>

<script>
import ShowLawyerReview from 'vue-app/scout/client/reviews/show-lawyer-review.vue';
import ShowFirmReview from 'vue-app/scout/client/reviews/show-firm-review.vue';
import ScoutReview from 'resources/scout/scout-review.js';

export default {
  name: 'ReviewsShow',

  components: {
    ShowLawyerReview,
    ShowFirmReview
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      review: new ScoutReview(this.initData.review, this.initData.reviewTemplate),
      resource: this.initData.resource,
      reviewTemplate: this.initData.reviewTemplate
    };
  },

  computed: {
    isLawyerReview() {
      return this.review.resourceType === 'Scout::LawyerBridge';
    }
  }
};
</script>
