<template>
  <div v-if="reason" class="normal-weight size-text-13px dark-gray-text">
    (locked for {{ reason }})
  </div>
</template>

<script>
export default {
  name: 'LockReason',

  props: {
    reason: {
      type: String,
      required: false
    }
  }
};
</script>
