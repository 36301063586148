import ScoutMatter from './scout-matter.js';
import axios from 'axios';
import BaseResource from 'resources/base-resource';

class ScoutLawyerBridge extends BaseResource {
  static baseUrl = '/scout/lawyer_bridges/:id.json';

  static scoutMatters(id, params) {
    return axios.get(`/scout/lawyer_bridges/${id}/scout_matters.json`, { params: params })
      .then((data) => {
        return data.data.map(matter => new ScoutMatter(matter));
      });
  }

  static rfqAvailableLawyers(params) {
    return axios.get('/scout/lawyer_bridges/rfq_available_lawyers.json', { params: params });
  }

  profileView(params) {
    return axios.get(`/scout/lawyer_bridges/${this.id}/profile_view.json`, { params: params });
  }

  isDeactivated() {
    return this.accessLevel === 'forbidden';
  }

  isBillingLawyer() {
    return this.accessLevel === 'billing_data';
  }

  isConnectedLawyer() {
    return this.accessLevel === 'connected_data';
  }

  isProspectiveLawyer() {
    return this.accessLevel === 'prospective_data';
  }

  hasBillingData() {
    return this.isConnectedLawyer() || this.isBillingLawyer();
  }

  hasBiographicalData() {
    return this.isConnectedLawyer() || this.isProspectiveLawyer();
  }

  titleAndFirm(hideFirm) {
    let display = this.firmTitle || '';

    if (!hideFirm) {
      display = _.compact([display, this.firmName]).join(' at ');
    }

    return display;
  }
}

export default ScoutLawyerBridge;
