<template>
  <div>
    <div class="text-right bottom-20">
      <button type="button" class="nv-button-clear-alt" @click="toggleFilters">
        <svg-icon name="filters" class="base-icon right-5"></svg-icon> Filters
      </button>
    </div>

    <div v-if="showFilters" class="d-flex wrap col-gap-8 row-gap-8">
      <filter-button
        v-for="filter in filters"
        :key="filter.field"
        :label="filter.label"
        :active="activeFilters.includes(filter.field)"
        :selected-options-count="searchService.selectedOptionsCount(filter.field)"
        color-pattern="purple"
        @open="open(filter)">
        <template #default="{ isVisible }" v-if="filter.type === 'multiselect'">
          <filter-multiselect
            :is-visible="isVisible"
            :field="filter.field"
            :filter-service="filterService"
            :search-service="searchService"
            :options="filter.options"
            :show-search="filter.showSearch"
            :on-search="onSearch">
          </filter-multiselect>
        </template>

        <filter-typeahead
          v-if="filter.type === 'typeahead'"
          :field="filter.field"
          :search-service="searchService"
          :options="filter.options"
          :on-search="onSearch">
        </filter-typeahead>
      </filter-button>

      <button type="button" class="pseudo-link-gray left-15" @click="resetFilters">
        Reset Filters
      </button>
    </div>
  </div>
</template>

<script>
import FilterTypeahead from 'vue-app/shared/components/filter-popovers/opportunities/filter-typeahead.vue';
import FilterMultiselect from 'vue-app/shared/components/filter-popovers/opportunities/filter-multiselect.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'OpportunityFilters',

  components: {
    FilterMultiselect,
    FilterTypeahead,
    SvgIcon
  },

  props: {
    filterService: {
      type: Object,
      required: true
    },

    searchService: {
      type: Object,
      required: true,
      deep: true
    },

    onSearch: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      showFilters: this.searchService.dirty || false,

      filters: [
        {
          type: 'typeahead',
          field: 'location',
          label: 'Location',
          options: [],
          showSearch: true
        },

        {
          type: 'multiselect',
          field: 'time_commitment',
          label: 'Time Commitment',
          options: [],
          showSearch: false
        },

        {
          type: 'multiselect',
          field: 'practice_areas',
          label: 'Practice Area',
          options: [],
          showSearch: true
        },

        {
          type: 'multiselect',
          field: 'site',
          label: 'On-Site/Remote',
          options: [],
          showSearch: false
        }
      ]
    };
  },

  computed: {
    activeFilters() {
      if (this.searchService) {
        return Object.keys(this.searchService.searchLogic.filters);
      }
    }
  },

  methods: {
    open(filter) {
      if (filter.options.length) { return; }

      this.filterService.getFilterOptions(filter.field)
        .then((response) => {
          filter.options = response;
        });
    },

    toggleFilters() {
      this.showFilters = !this.showFilters;
    },

    resetFilters() {
      if (!this.searchService.dirty) { return; }

      this.searchService.resetSearch();
      this.onSearch();
    }
  }
};
</script>
