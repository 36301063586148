<template>
  <div :class="['list-item matter-row no-hover', { 'expanded': isExpanded }]">
    <div class="row tight-columns vertical-center-not-xs">
      <div class="col-sm-4">
        <lawyer-name-plate :lawyer="lawyer" :hide-firm="false" photo-size="small" :show-firm-link="false">
          <template #lawyer-name-element>
            <a :href="`/scout-company/lawyers/${lawyer.id}`" class="text-link" target="_blank">{{ lawyer.fullName }}</a>
          </template>
        </lawyer-name-plate>
      </div>

      <div class="col-sm-5 top-10-xs d-flex vertical-center-not-xs flex-space-between-not-xs flex-direction-row-not-xs row-gap-5 col-gap-10">
        <div class="flex-4">
          <div class="semibold-weight size-text-12px">
            Total Billed
          </div>

          <div :class="['text-ellipsis', { 'missing-text': matterLawyer.placeholder }]">
            {{ feeTotal }}
          </div>
        </div>

        <div class="flex-4">
          <div class="semibold-weight size-text-12px">
            Total Hours
          </div>

          <div :class="['text-ellipsis', { 'missing-text': matterLawyer.placeholder }]">
            {{ hoursText }}
          </div>
        </div>

        <div class="flex-7">
          <div v-if="diversityDisclosureDataIsPresent">
            <div class="size-text-12px semibold-weight">
              Diversity Disclosure
            </div>

            <div>
              {{ diversityDisclosureText }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-3">
        <div class="row tight-columns vertical-center-not-xs top-10-xs">
          <div class="col-sm-6 text-nowrap">
            <a :href="newReviewUrl" class="bold-weight blue-link" target="_self">Leave a review</a>

            <div>
              {{ reviewsCountText }}
            </div>
          </div>

          <div class="col-sm-6 text-right-not-xs">
            <button type="button" class="nv-button-square-white" @click="toggleReviews"><svg-icon name="caret-right" :class="['base-icon disclosure-icon', { 'expanded': isExpanded }]"></svg-icon></button>
          </div>
        </div>
      </div>
    </div>

    <div class="top-20" v-if="isExpanded">
      <div class="top-20" v-if="hasReviews">
        <scout-review-summary
          v-for="review in matterLawyer.reviews"
          :key="`review-${review.id}`"
          :review="review"
          :review-template="reviewTemplate"
          class="list-spacing-10">
        </scout-review-summary>
      </div>
    </div>
  </div>
</template>

<script>
import LawyerNamePlate from 'vue-app/scout/shared/lawyer-name-plate.vue';
import ScoutReviewSummary from 'vue-app/scout/client/lawyers/reviews/scout-review-summary.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import ScoutLawyerBridge from 'resources/scout/scout-lawyer-bridge.js';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter.js';

export default {
  name: 'MatterLawyer',

  components: {
    LawyerNamePlate,
    ScoutReviewSummary,
    SvgIcon
  },

  props: {
    matterLawyer: {
      type: Object,
      required: true
    },

    reviewTemplate: {
      type: Object,
      required: true
    },

    matter: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      isExpanded: false
    };
  },

  computed: {
    lawyer() {
      return new ScoutLawyerBridge(this.matterLawyer.lawyer);
    },

    feeTotal() {
      if (this.matterLawyer.placeholder) { return 'Not Available'; }

      const fee = this.matterLawyer.feeTotalInUsCents;
      if (typeof fee !== 'number') {
        return fee;
      }
      else {
        return CurrencyFilter.filter(fee / 100);
      }
    },

    hoursText() {
      if (this.matterLawyer.placeholder) { return 'Not Available'; }

      return this.matterLawyer.hours ? this.$pluralize('hr', this.matterLawyer.hours, true) : '—';
    },

    hasReviews() {
      return this.matterLawyer.reviews.length > 0;
    },

    reviewsCountText() {
      return this.hasReviews ? this.$pluralize('review', this.matterLawyer.reviews.length, true) : 'No reviews';
    },

    newReviewUrl() {
      if (this.matter.id) {
        return `/scout-company/lawyers/${this.lawyer.id}/reviews/new?matterId=${this.matter.id}`;
      }
      else {
        return `/scout-company/lawyers/${this.lawyer.id}/reviews/new`;
      }
    },

    diversityDisclosureData() {
      let data = [];

      // ethnicity
      this.lawyer.userEthnicities.forEach(ethnicity => {
        data.push(ethnicity.ethnicityName);
      });

      // gender
      this.lawyer.genderProfileTaggings.forEach(tagging => {
        data.push(tagging.presentedName);
      });

      // lgbtq
      if (this.lawyer.identifiesAsLgbtq === 'identifiesAsLgbtq') {
        data.push('LGBTQ+');
      }

      // differently abled
      if (this.lawyer.identifiesAsDifferentlyAbled === 'identifiesAsDifferentlyAbled') {
        data.push('Disabled Person');
      }

      // military veteran
      if (this.lawyer.isMilitaryVeteran === 'isMilitaryVeteran') {
        data.push('Military Veteran');
      }

      return data;
    },

    diversityDisclosureDataIsPresent() {
      return this.diversityDisclosureData.length > 0;
    },

    diversityDisclosureText() {
      return this.diversityDisclosureData.join(', ');
    }
  },

  methods: {
    toggleReviews() {
      this.isExpanded = !this.isExpanded;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .list-item.matter-row:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .resource-list.static-list .list-item {
    padding: 20px 30px;
    border-bottom-color: $k-gray;

    &.expanded {
      padding-left: 25px;
    }
  }

  .full-width {
    width: 100%
  }
</style>
