import { render, staticRenderFns } from "./lawyers-list.vue?vue&type=template&id=cc5c3c4c&scoped=true"
import script from "./lawyers-list.vue?vue&type=script&lang=js"
export * from "./lawyers-list.vue?vue&type=script&lang=js"
import style0 from "./lawyers-list.vue?vue&type=style&index=0&id=cc5c3c4c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc5c3c4c",
  null
  
)

export default component.exports