<template>
  <div class="shadowed-box" v-show="displayComponent()">
    <div class="tab-wrapper">
      <div class="row tight-columns vertical-center-not-xs">
        <div class="col-xs-8 col-sm-4 text-uppercase tab-header bold-weight">
          Locations
        </div>

        <div class="col-xs-4 col-sm-1 col-sm-push-7 tab-header text-right" v-if="canEditProfile">
          <edit-firm-office-locations
            :firm="firm"
            :office-locations="locations"
            :on-save="onSave">
          </edit-firm-office-locations>
        </div>

        <div :class="['vertical-center flex-end-not-xs gap-5 top-10-xs', { 'col-sm-7 col-sm-pull-1': canEditProfile, 'col-xs-12 col-sm-8': !canEditProfile }]">
          <div v-if="globalDataIsPresent" data-testid="global-tab" :class="['tab-item', { 'selected-tab purple-tab': globalSelected }]" @click.prevent="selectTab('global')">Global</div>

          <div v-if="unitedStatesDataIsPresent" data-testid="united-states-tab" :class="['tab-item', { 'selected-tab purple-tab': unitedStatesSelected }]" @click.prevent="selectTab('unitedStates')">United States</div>

          <div v-if="tagsPresent" :class="['tab-item purple-tab', { 'selected-tab': taggedLocationsSelected }]" @click.prevent="selectTab('tagged-locations')"><span class="tagged-tab purple-tab">Tagged Locations</span></div>
        </div>
      </div>
    </div>

    <div class="box-content">
      <loading-section name="firm-office-locations">
        <div v-if="globalSelected || unitedStatesSelected">
          <div v-if="lawyerCountsPresent" class="bold-weight size-text-14px text-center bottom-15">
            Top Office Locations
          </div>

          <div v-if="globalSelected">
            <display-rules-section-wrapper v-if="lawyerCountsPresent" :data="topLocations" :editing-allowed="canEditProfile">
              <display-rules-data-wrapper class="size-text-12px" :data="topLocations">
                <bar-chart :chart-data="topLocations" :unit-symbol="barChartUnits"></bar-chart>
              </display-rules-data-wrapper>
            </display-rules-section-wrapper>

            <div v-if="showAll || !lawyerCountsPresent">
              <div v-if="lawyerCountsPresent" class="bottom-15 top-40">
                <div class="bold-weight size-text-14px">
                  All Office Locations
                </div>

                <div v-if="!lawyerCountsArePercentages">
                  <span class="bold-weight">Total Lawyers:</span> {{ totalLawyers }}
                </div>
              </div>

              <collapsable-space :max-height="showDisplayToggle ? Infinity : 190">
                <display-rules-section-wrapper :data="otherGlobalLocations" :editing-allowed="canEditProfile">
                  <display-rules-data-wrapper class="size-text-12px" :data="otherGlobalLocations">
                    <div :data-testid="'continent-wrapper-' + continent" v-for="(continentLocations, continent, index) in otherGlobalLocations" :key="continent">
                      <div :class="['bold-weight', 'bottom-10', { 'top-20': index > 0 }]">
                        {{ continent }}
                      </div>

                      <div class="d-flex wrap col-gap-15 row-gap-15">
                        <div v-for="location in continentLocations">
                          <multi-piece-tag
                            :key="location.city"
                            :text="location.country"
                            :sub-text="subTextFor(location)"
                            :number="roundedLawyersCount(location)"
                            :unit="location.lawyersCountIsPercentage ? '%' : null">
                          </multi-piece-tag>
                        </div>
                      </div>
                    </div>
                  </display-rules-data-wrapper>
                </display-rules-section-wrapper>
              </collapsable-space>
            </div>
          </div>

          <div v-if="unitedStatesSelected">
            <display-rules-section-wrapper v-if="lawyerCountsPresent" :data="topLocations" :editing-allowed="canEditProfile">
              <display-rules-data-wrapper class="size-text-12px" :data="topLocations">
                <bar-chart :chart-data="topLocations" :unit-symbol="barChartUnits"></bar-chart>
              </display-rules-data-wrapper>
            </display-rules-section-wrapper>

            <div v-if="showAll || !lawyerCountsPresent">
              <div v-if="lawyerCountsPresent" class="bottom-15 top-40">
                <div class="bold-weight size-text-14px">
                  All Office Locations
                </div>

                <div v-if="!lawyerCountsArePercentages">
                  <span class="bold-weight">Total Lawyers:</span> {{ totalUsLawyers }}
                </div>
              </div>

              <collapsable-space :max-height="showDisplayToggle ? Infinity : 190">
                <display-rules-section-wrapper :data="otherUnitedStatesLocations" :editing-allowed="canEditProfile">
                  <display-rules-data-wrapper class="size-text-12px" :data="otherUnitedStatesLocations">
                    <div v-for="(locationsInRange, alphabetRange, index) in otherUnitedStatesLocations" :key="alphabetRange">
                      <div :class="['bold-weight', 'bottom-10', { 'top-20': index > 0 }]">
                        {{ alphabetRange }}
                      </div>

                      <div class="d-flex wrap col-gap-15 row-gap-15">
                        <div v-for="location in locationsInRange">
                          <multi-piece-tag
                            :key="location.city"
                            :text="location.state"
                            :sub-text="location.city"
                            :number="roundedLawyersCount(location)"
                            :unit="location.lawyersCountIsPercentage ? '%' : null">
                          </multi-piece-tag>
                        </div>
                      </div>
                    </div>
                  </display-rules-data-wrapper>
                </display-rules-section-wrapper>
              </collapsable-space>
            </div>
          </div>
        </div>

        <div v-if="taggedLocationsSelected">
          <collapsable-space :max-height="190">
            <grouped-tags :firm="firm" :group-by="'location'"></grouped-tags>
          </collapsable-space>
        </div>

        <div class="top-30" v-if="showDisplayToggle && (globalSelected || unitedStatesSelected)">
          <a href="" class="semibold-weight" @click.prevent="toggleShowAll">{{ showAllOrFewer }}</a>
        </div>
      </loading-section>
    </div>

    <div class="box-footer pill-gray-text" v-if="showDisplayToggle && (globalSelected || unitedStatesSelected)">
      <svg-icon name="info" class="base-icon smaller right-10"></svg-icon><span class="vertical-middle">Displays lawyers per office location</span>
    </div>
  </div>
</template>

<script>
import BarChart from 'vue-app/shared/components/bar-chart.vue';
import DisplayRulesSectionWrapper from 'vue-app/shared/components/display-rules/display-rules-section-wrapper.vue';
import DisplayRulesDataWrapper from 'vue-app/shared/components/display-rules/display-rules-data-wrapper.vue';
import EditFirmOfficeLocations from 'vue-app/scout/shared/law-firms/edit-firm-office-locations.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import MultiPieceTag from 'vue-app/shared/components/multi-piece-tag.vue';
import CollapsableSpace from 'vue-app/shared/components/collapsable-space.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import GroupedTags from 'vue-app/scout/shared/law-firms/grouped-tags.vue';

import PrioriColors from 'vue-app/shared/misc/priori-colors';
import displayRules from 'vue-app/shared/mixins/display-rules';

import { orderBy, filter, map, groupBy, sumBy, omitBy, isEmpty, some } from 'lodash';

export default {
  name: 'FirmOfficeLocations',

  components: {
    BarChart,
    DisplayRulesSectionWrapper,
    DisplayRulesDataWrapper,
    SvgIcon,
    EditFirmOfficeLocations,
    MultiPieceTag,
    CollapsableSpace,
    LoadingSection,
    GroupedTags
  },

  mixins: [displayRules],

  props: {
    firm: {
      type:     Object,
      required: true
    },

    canEditProfile: {
      type:    Boolean,
      default: false
    },

    onSave: {
      type:    Function,
      default: () => {}
    }
  },

  data: function () {
    return {
      selectedTab: '',
      showAll: false,
      initialDisplayCount: 7
    };
  },

  computed: {
    locations() {
      return this.firm.locations;
    },

    barChartUnits() {
      return some(this.locations, (location) => location.lawyersCountIsPercentage) ? '%' : null;
    },

    aggregatedLocations() {
      const groupedLocations = groupBy(this.locations, location => `${location.country},${location.city},${location.state || ''}`);

      return map(groupedLocations, locationGroup => ({
        continent: locationGroup[0].continent === 'Australia' ? 'Oceania' : locationGroup[0].continent,
        country: locationGroup[0].country,
        city: locationGroup[0].city,
        state: locationGroup[0].state,
        isHeadquarters: locationGroup[0].isHeadquarters,
        lawyersCount: sumBy(locationGroup, location => parseFloat(location.lawyersCount || 0)),
        lawyersCountIsPercentage: locationGroup[0].lawyersCountIsPercentage
      }));
    },

    totalLawyers() {
      return sumBy(this.locations, location => parseFloat(location.lawyersCount || 0)).toLocaleString();
    },

    totalUsLawyers() {
      const usLocations = filter(this.locations, location => location.country === 'United States');

      return sumBy(usLocations, location => parseFloat(location.lawyersCount || 0)).toLocaleString();
    },

    sortedByLawyersCountLocations() {
      const withLawyersCount = filter(this.aggregatedLocations, 'lawyersCount');

      return orderBy(withLawyersCount, location => location.lawyersCount, ['desc']);
    },

    sortedByLocationLocations() {
      if (this.unitedStatesSelected) {
        return orderBy(this.aggregatedLocations, ['continent', 'state', 'city'], ['asc']);
      }
      else {
        return orderBy(this.aggregatedLocations, ['continent', 'country', 'city'], ['asc']);
      }
    },

    globalSelected() {
      return this.selectedTab === 'global';
    },

    unitedStatesSelected() {
      return this.selectedTab === 'unitedStates';
    },

    taggedLocationsSelected() {
      return this.selectedTab === 'tagged-locations';
    },

    lawyerCountsPresent() {
      return !!this.topLocations.length;
    },

    topLocations() {
      return this.globalSelected ? this.topGlobalLocations : this.topUnitedStatesLocations;
    },

    topGlobalLocations() {
      return this.chartDataFor(this.sortedByLawyersCountLocations.slice(0, this.initialDisplayCount));
    },

    otherGlobalLocations() {
      return groupBy(this.sortedByLocationLocations, 'continent');
    },

    topUnitedStatesLocations() {
      const sortedUsLocations = filter(this.sortedByLawyersCountLocations, (location) => location.country === 'United States');

      return this.chartDataFor(sortedUsLocations.slice(0, this.initialDisplayCount));
    },

    otherUnitedStatesLocations() {
      const sortedUsLocations = filter(this.sortedByLocationLocations, (location) => location.country === 'United States');

      return omitBy({
        'A–F': this.byAlphabetRange(sortedUsLocations, 'A', 'F'),
        'G–L': this.byAlphabetRange(sortedUsLocations, 'G', 'L'),
        'M–R': this.byAlphabetRange(sortedUsLocations, 'M', 'R'),
        'S–Z': this.byAlphabetRange(sortedUsLocations, 'S', 'Z')
      }, isEmpty);
    },

    showAllOrFewer() {
      return this.showAll ? 'Show less' : 'Show all';
    },

    showDisplayToggle() {
      return this.topLocations.length;
    },

    globalDataIsPresent() {
      return this.dataIsPresent(this.otherGlobalLocations);
    },

    unitedStatesDataIsPresent() {
      return this.dataIsPresent(this.otherUnitedStatesLocations);
    },

    tagsPresent() {
      return filter(this.firm.scoutTags, tag => tag.tagType === 'location').length > 0;
    },

    lawyerCountsArePercentages() {
      return some(this.locations, (location) => location.lawyersCountIsPercentage);
    }
  },

  created() {
    this.selectedTab = (!this.anyDataIsPresent([this.locations]) && this.tagsPresent) ? 'tagged-locations' : 'global';
  },

  methods: {
    displayComponent() {
      return this.anyDataIsPresent([this.locations]) || this.canEditProfile || this.tagsPresent;
    },

    locationLabel(location) {
      const { country, state, city } = location;

      return this.unitedStatesSelected ? `${city}, ${state}` : `${city}, ${country}`;
    },

    selectTab(tab) {
      this.selectedTab = tab;
    },

    chartDataFor: function (locations) {
      const colors = PrioriColors.generate(locations.length, 'purpleShades');
      let data = [];

      locations.forEach(location => {
        data.push(
          {
            icon: location.isHeadquarters ? 'headquarters' : null,
            name: this.locationLabel(location),
            color: colors.shift(),
            value: location.lawyersCount
          }
        );
      });

      return data;
    },

    subTextFor(location) {
      if (location.country === 'United States') {
        return [location.city, location.state].join(', ');
      }

      return location.city;
    },

    toggleShowAll() {
      this.showAll = !this.showAll;
    },

    byAlphabetRange(locations, startChar, finishChar) {
      return filter(locations, (location) => {
        const firstChar = location.state.charAt(0).toUpperCase();

        return firstChar >= startChar && firstChar <= finishChar;
      });
    },

    roundedLawyersCount(location) {
      const count = location.lawyersCount;

      if (!count) { return null; }

      const decimalPlaces = count % 1 !== 0 ? 1 : 0;

      return parseFloat(count.toFixed(decimalPlaces));
    },

    formatNumber(num) {
      return num % 1 !== 0 ? num.toFixed(1) : num;
    }
  }
};
</script>

<style lang="scss" scoped>
  :deep(.faded-section.fade-down)::after {
    height: 40px;
  }

  :deep(.item-icon .base-icon) {
    fill: none;
  }
</style>
