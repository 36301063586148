<template>
  <dropdown-menu>
    <li class="menu-item" role="menuitem" v-if="possibleLawyer.requestedStatus()">
      <a href="" @click.prevent="onScheduleCall"><svg-icon name="calendar" class="base-icon"></svg-icon>Schedule Call</a>
    </li>

    <li class="menu-item" role="menuitem">
      <a href="" @click.prevent="onMessageClient"><svg-icon name="envelope" class="base-icon"></svg-icon>Message Client</a>
    </li>

    <li class="menu-item" role="menuitem">
      <a href="" @click.prevent="onSendProposal"><svg-icon name="writing" class="base-icon"></svg-icon>{{ proposalActionText }}</a>
    </li>

    <li class="menu-item" role="menuitem">
      <a href="" @click.prevent="onRejectRequest"><svg-icon name="reject" class="base-icon"></svg-icon>Decline Request</a>
    </li>
  </dropdown-menu>
</template>

<script>
import DropdownMenu from 'vue-app/shared/components/dropdown-menu.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'RequestOptionsTooltip',

  components: {
    DropdownMenu,
    SvgIcon
  },

  props: {
    possibleLawyer: {
      type: Object,
      default: () => ({})
    },

    onMessageClient: {
      type: Function,
      required: true
    },

    onSendProposal: {
      type: Function,
      required: true
    },

    onScheduleCall: {
      type: Function,
      required: true
    },

    onRejectRequest: {
      type: Function,
      required: true
    }
  },

  computed: {
    proposalActionText() {
      if (this.possibleLawyer.rateSentStatus()) {
        return 'Edit Proposal';
      }
      else if (this.possibleLawyer.isDraft()) {
        return 'Finish Proposal';
      }
      else {
        return 'Build Proposal';
      }
    }
  }
};
</script>

