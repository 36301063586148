<template>
  <div>
    <div class="row bottom-30">
      <div class="col-sm-8">
        <matter-graphics
          :formatted-practice-areas="formattedPracticeAreas">
        </matter-graphics>
      </div>
    </div>

    <div v-if="formattedTimelinessBreakdown.length > 0" class="bottom-30">
      <firm-invoice-timeliness-breakdown
        :formatted-timeliness-breakdown="formattedTimelinessBreakdown">
      </firm-invoice-timeliness-breakdown>
    </div>

    <div class="bottom-20">
      <div class="row">
        <div class="col-sm-4">
          <search-input
            class="right-10-not-xs"
            placeholder="Search by Matter Number"
            :on-search="searchMatterByNumber"
            :on-reset="resetCodeFilter">
          </search-input>
        </div>

        <div class="col-sm-8 top-20-xs">
          <loading-section name="mattersListFilters">
            <matters-list-filters
              :show-filters="['activityDate', 'matterManagers']"
              :date-filter-years-range="matterActivityYearRange"
              :on-apply-filter="filterMatters"
              :matter-managers="matterManagers">
            </matters-list-filters>
          </loading-section>
        </div>
      </div>
    </div>

    <loading-section name="firm-matters">
      <matters-list
        :matters="matters"
        :matter-info-tooltip-text="matterInfoTooltipText"
        @load-lawyers="onLoadLawyers">
      </matters-list>

      <div class="scout-pagination top-15" v-if="matters.length">
        <priori-pagination
          :current-page="pagination.currentPage"
          :total-entries="pagination.totalEntries"
          :per-page="pagination.perPage"
          @change="onPageChange">
        </priori-pagination>
      </div>
    </loading-section>
  </div>
</template>

<script>
import FirmInvoiceTimelinessBreakdown from 'vue-app/scout/client/law-firms/firm-invoice-timeliness-breakdown.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import MatterGraphics from 'vue-app/scout/client/law-firms/matter-graphics.vue';
import MattersList from 'vue-app/scout/client/matters/matters-list.vue';
import MattersListFilters from 'vue-app/scout/client/matters/matters-list-filters.vue';
import PrioriColors from 'vue-app/shared/misc/priori-colors';
import PrioriPagination from 'vue-app/shared/components/priori-pagination.vue';
import SearchInput from 'vue-app/shared/components/search-input.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'FirmMatters',

  components: {
    FirmInvoiceTimelinessBreakdown,
    LoadingSection,
    MatterGraphics,
    MattersList,
    MattersListFilters,
    PrioriPagination,
    SearchInput
  },

  props: {
    practiceAreas: {
      type: Array,
      required: true
    },

    invoiceTimelinessBreakdown: {
      type: Array,
      required: true
    },

    matterActivityYearRange: {
      type: Array,
      required: true
    },

    matterManagers: {
      type: Array,
      required: true
    },

    matters: {
      type: Array,
      required: true
    },

    pagination: {
      type: Object,
      required: true
    },

    onFilterMatters: {
      type: Function,
      required: true
    },

    onLoadLawyers: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      matterNumber: { value: '' },
      filterParams: { page: 1 }
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentClient: 'currentUser' }),

    formattedPracticeAreas() {
      const colors = PrioriColors.generate(this.practiceAreas.length);

      return this.practiceAreas.map((practiceArea) => {
        return {
          name: practiceArea.name,
          color: colors.shift(),
          matterCount: practiceArea.matterCount,
          dollarsSpent: practiceArea.matterSpendInCents / 100
        };
      });
    },

    formattedTimelinessBreakdown() {
      return Object.values(this.invoiceTimelinessBreakdown).map(breakdown => {
        const practiceArea = this.formattedPracticeAreas.find(p => p.name === breakdown.practiceAreaName);

        return {
          ...breakdown,
          color: practiceArea.color
        };
      });
    },

    matterInfoTooltipText() {
      return this.currentClient.workspace.scoutLawFirmMattersInfoTooltipText;
    }
  },

  methods: {
    filterMatters(filterParams) {
      this.filterParams = filterParams;

      this.onFilterMatters(this.filterParams);
    },

    searchMatterByNumber(matterNumber) {
      this.filterParams = { ...this.filterParams, code: matterNumber };

      this.onFilterMatters(this.filterParams);
    },

    onPageChange(page) {
      this.filterParams = { ...this.filterParams, page: page };

      this.onFilterMatters(this.filterParams);
    },

    resetCodeFilter() {
      this.matterNumber = { value: '' };
      delete this.filterParams.code;

      this.onFilterMatters(this.filterParams);
    }
  }
};
</script>
