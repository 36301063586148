<template>
  <div class="shadowed-box">
    <div class="box-content">
      <div class="semibold-weight bottom-10">
        Warning: Do not service!
      </div>

      <div class="row">
        <div class="col-xs-9 col-sm-8">
          <div class="two-btn-group alternate-group">
            <button type="button" :class="{ 'selected': workspace.doNotService }" @click="toggleDoNotService(true)">Yes</button>
            <button type="button" :class="{ 'selected': !workspace.doNotService }" @click="toggleDoNotService(false)">No</button>
          </div>
        </div>

        <div class="col-xs-3 col-sm-4 top-5">
          <span v-if="workspace.doNotService" class="tag-list-item compact clear">
            <svg-icon name="warning" class="base-icon red-text"></svg-icon>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import AdminWorkspace from 'resources/admin/admin-workspace.js';

export default {
  name: 'WorkspaceStatus',

  components: {
    SvgIcon
  },

  props: {
    workspace: {
      type: Object,
      required: true
    }
  },

  methods: {
    toggleDoNotService(val) {
      if (this.workspace.doNotService === val) { return; }

      this.workspace.doNotService = val;

      return AdminWorkspace.update({
        id: this.workspace.id,
        workspace: { doNotService: val }
      });
    }
  }
};
</script>
