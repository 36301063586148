<template>
  <div>
    <matter-titlebar
      :matter="matter"
      :send-review-reminders="sendReviewReminders">
    </matter-titlebar>

    <div class="sidenav-content">
      <div class="container-fluid">
        <div class="padded-container">
          <div class="row d-flex-md">
            <div class="col-md-4 bottom-30">
              <div class="shadowed-box height-100">
                <div class="box-header text-uppercase">
                  Average Matter Score
                </div>

                <div class="box-content symmetrical">
                  <reviews-average-score
                    :reviews-summary="matter.reviewSummary">
                  </reviews-average-score>
                </div>
              </div>
            </div>

            <div :class="['col-md-4', { 'bottom-30': matter.reviewSummary.count > 0 }]">
              <div class="shadowed-box height-100" v-if="matter.reviewSummary.count > 0">
                <div class="box-header text-uppercase">
                  Performance
                </div>

                <div class="box-content symmetrical">
                  <loading-section name="reviewTemplates" :render-after-loading="true">
                    <performance-score-with-top-label
                      v-if="lawyerReviewTemplate"
                      :review-template="lawyerReviewTemplate"
                      :scores="matter.reviewSummary.scoreAverages"
                      fill-color-class="blue-purple-background">
                    </performance-score-with-top-label>
                  </loading-section>
                </div>
              </div>
            </div>

            <div v-if="matter.scoutRfqId" class="col-md-4 bottom-30">
              <div class="shadowed-box height-100">
                <div class="box-header text-uppercase">
                  RFP Budget Tracking
                </div>

                <div class="box-content symmetrical">
                  <loading-section name="matterRFQ" :render-after-loading="true">
                    <budget-tracker
                      :matter="matter"
                      :rfq="rfq">
                    </budget-tracker>
                  </loading-section>
                </div>
              </div>
            </div>
          </div>

          <div class="shadowed-box">
            <div class="box-header text-uppercase">
              Reviews
            </div>

            <loading-section name="reviewTemplates" :render-after-loading="true">
              <div class="resource-list static-list">
                <template v-if="firmReviewTemplate">
                  <matter-law-firm
                    v-for="lawFirm in lawFirms"
                    :key="lawFirm.id"
                    :law-firm="lawFirm"
                    :review-template="firmReviewTemplate"
                    :matter="matter">
                  </matter-law-firm>
                </template>

                <template v-if="lawyerReviewTemplate">
                  <matter-lawyer
                    v-for="matterLawyer in matter.scoutMatterLawyers"
                    :key="matterLawyer.id"
                    :matter-lawyer="matterLawyer"
                    :review-template="lawyerReviewTemplate"
                    :matter="matter">
                  </matter-lawyer>
                </template>

                <div class="box-content symmetrical">
                  <div class="row tight-columns review-list-item">
                    <div class="col-sm-6 size-text-14px top-4">
                      Total
                    </div>

                    <div class="col-sm-6 text-right-not-xs size-text-18px bold-weight">
                      {{ feeTotal }}
                    </div>
                  </div>
                </div>
              </div>
            </loading-section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MatterTitlebar from 'vue-app/scout/client/matters/matter-titlebar.vue';
import BudgetTracker from 'vue-app/scout/client/matters/budget-tracker.vue';
import MatterLawFirm from 'vue-app/scout/client/matters/matter-law-firm.vue';
import MatterLawyer from 'vue-app/scout/client/matters/matter-lawyer.vue';
import ReviewsAverageScore from 'vue-app/scout/client/shared/reviews-average-score.vue';
import PerformanceScoreWithTopLabel from 'vue-app/scout/shared/performance-score-with-top-label.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import ScoutFirmBridge from 'resources/scout/scout-firm-bridge.js';
import ScoutLawyerReviewTemplate from 'resources/scout/client/scout-lawyer-review-template.js';
import ScoutFirmReviewTemplate from 'resources/scout/client/scout-firm-review-template.js';
import ScoutManualReviewReminder from 'resources/scout/client/scout-manual-review-reminder.js';
import RFQ from 'src/resources/scout/rfq.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import CurrencyFilter from 'vue-app/shared/lib/currency-filter.js';

export default {
  name: 'MattersShow',

  components: {
    MatterTitlebar,
    BudgetTracker,
    MatterLawFirm,
    MatterLawyer,
    ReviewsAverageScore,
    PerformanceScoreWithTopLabel,
    LoadingSection
  },

  props: {
    initData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      matter: this.initData.matter,
      rfq: {},
      lawyerReviewTemplate: null,
      firmReviewTemplate: null
    };
  },

  computed: {
    lawFirms() {
      return this.matter.scoutMatterLawFirms.map(firm => new ScoutFirmBridge(firm));
    },

    feeTotal() {
      if (typeof this.matter.feeTotalInUsCents === 'number') {
        return CurrencyFilter.filter(this.matter.feeTotalInUsCents / 100);
      }
      else {
        return this.matter.feeTotalInUsCents;
      }
    }
  },

  mounted() {
    if (this.matter.scoutRfqId) {
      LoadingService.around('matterRFQ', () => {
        return RFQ.budgetPermissions(this.matter.scoutRfqId).then((response) => {
          this.rfq = response.data;
        });
      });
    }

    LoadingService.loading('reviewTemplates');
    Promise.all([
      this.fetchLawyerReviewTemplate(),
      this.fetchFirmReviewTemplate()
    ])
    .then(([lawyerReviewTemplate, firmReviewTemplate]) => {
      this.lawyerReviewTemplate = lawyerReviewTemplate;
      this.firmReviewTemplate = firmReviewTemplate;
      LoadingService.done('reviewTemplates');
    });
  },

  methods: {
    fetchLawyerReviewTemplate() {
      return ScoutLawyerReviewTemplate.inWorkspace();
    },

    fetchFirmReviewTemplate() {
      return ScoutFirmReviewTemplate.inWorkspace();
    },

    sendReviewReminders(recipientIds, customMessage) {
      return ScoutManualReviewReminder.save({
        scoutMatterId: this.matter.id,
        recipientIds: recipientIds,
        customMessage: customMessage
      });
    }
  }
};
</script>
