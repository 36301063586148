import { uniqueId } from 'lodash';

/**
 *  A firm can add 1 of 3 different types of lawyers
 *  as SuggestedLawyers when responding to an RFP.
 *
 *  1. LawyerBridge - Connected firms are responding to RFPs from Scout clients.
 *  2. Lawyer - Connected firms are responding to RFPs from Feature Lab clients.
 *  3. Lawyer Name - Free text input saved as lawyer name on the suggested lawyer.
 *
 */
class RfqSuggestedLawyer {
  constructor(lawyerData) {
    const isExisting = this.isExistingSuggestedLawyer(lawyerData);

    this.id = isExisting ? lawyerData.id : null;
    this.scoutLawyerBridge = isExisting ? lawyerData.scoutLawyerBridge : this.getLawyerBridge(lawyerData);
    this.scoutLawyerBridgeId = isExisting ? lawyerData.scoutLawyerBridgeId : this.getLawyerBridgeId(lawyerData);
    this.lawyer = isExisting ? lawyerData.lawyer : this.getLawyer(lawyerData);
    this.lawyerId = isExisting ? lawyerData.lawyerId : this.getLawyerId(lawyerData);
    this.proposedHourlyRate = isExisting ? lawyerData.proposedHourlyRate : null;
    this.suggestedBy = isExisting ? lawyerData.suggestedBy : 'lawyer';
    this.lawyerName = lawyerData.lawyerName;
  }

  isExistingSuggestedLawyer(lawyerData) {
    return lawyerData.suggestedBy !== undefined;
  }

  getLawyer(lawyerData) {
    if (this.isLawyerBridge(lawyerData)) { return null; }

    if (lawyerData.lawyerName === null || lawyerData.lawyerName === undefined) {
      return lawyerData;
    }

    return null;
  }

  getLawyerId(lawyerData) {
    if (this.isLawyerBridge(lawyerData)) { return null; }

    if (lawyerData.id) { return lawyerData.id; }

    return null;
  }

  getLawyerBridge(lawyerData) {
    if (lawyerData.scoutLawyerBridge) { return lawyerData.scoutLawyerBridge; }

    if (this.isLawyerBridge(lawyerData)) { return lawyerData; }
  }

  getLawyerBridgeId(lawyerData) {
    if (lawyerData.scoutLawyerBridge) { return lawyerData.scoutLawyerBridge.id; }

    if (this.isLawyerBridge(lawyerData)) { return lawyerData.id; }

    return null;
  }

  get isBasicLawyer() {
    return this.lawyer !== null && this.lawyer !== undefined;
  }

  fullName() {
    if (this.scoutLawyerBridge || this.accessLevel !== undefined) {
      return (this.scoutLawyerBridge?.accessLevel || this.accessLevel) === 'forbidden' ? 'Deactivated User' : this.scoutLawyerBridge.fullName;
    }
    else if (this.isBasicLawyer) {
      return this.lawyer.fullName;
    }
    else {
      return this.lawyerName;
    }
  }

  firmTitle() {
    if (this.scoutLawyerBridge || this.accessLevel !== undefined) {
      return (this.scoutLawyerBridge?.accessLevel || this.accessLevel) === 'forbidden' ? '' : this.scoutLawyerBridge.firmTitle;
    }
    else if (this.isBasicLawyer) {
      return this.lawyer.firmTitle;
    }
    else {
      return null;
    }
  }

  isLawyerBridge(lawyerData) {
    return lawyerData.scoutLawyerBridge || lawyerData.accessLevel !== undefined;
  }

  toFormData() {
    return {
      id: this.id,
      formId: uniqueId('firm-suggested-'),
      scoutLawyerBridge: this.scoutLawyerBridge,
      scoutLawyerBridgeId: this.scoutLawyerBridgeId,
      lawyer: this.lawyer,
      lawyerId: this.lawyerId,
      lawyerName: this.lawyerName,
      proposedHourlyRate: this.proposedHourlyRate,
      suggestedBy: 'lawyer'
    };
  }

  fullNameEquals(fullName) {
    const lowerCaseName = fullName.toLowerCase();

    const lawyerToCheck = this.isBasicLawyer ? this.lawyer : this.scoutLawyerBridge;

    if (lawyerToCheck && lawyerToCheck.fullName && lawyerToCheck.fullName.toLowerCase() === lowerCaseName) {
      return true;
    }
    else if (this.lawyerName && this.lawyerName.toLowerCase() === lowerCaseName) {
      return true;
    }

    return false;
  }
}

export default RfqSuggestedLawyer;
