<template>
  <div class="row">
    <div class="col-sm-8">
      <data-disclaimer-box v-if="showDataDisclaimerBox" class="bottom-30" :firm-name="firm.name"></data-disclaimer-box>

      <firm-about ref="firmAbout" class="bottom-30" :firm="firm"></firm-about>

      <firm-practice-areas ref="firmPracticeAreas" class="bottom-30" :firm="firm"></firm-practice-areas>

      <firm-office-locations ref="firmOfficeLocations" class="bottom-30" :firm="firm"></firm-office-locations>

      <firm-diversity ref="firmDiversity" class="bottom-30" :firm="firm"></firm-diversity>

      <firm-representative-matters ref="firmRepresentativeMatters" :matters="firm.representativeMatters"></firm-representative-matters>
    </div>

    <div class="col-sm-4 top-30-xs">
      <firm-status class="bottom-30" :firm="firm"></firm-status>

      <firm-relationship-partners class="bottom-30" :firm="firm"></firm-relationship-partners>

      <firm-awards class="bottom-30" :firm="firm"></firm-awards>

      <firm-highlights class="bottom-30" :firm="firm"></firm-highlights>

      <firm-leadership class="bottom-30" :firm="firm"></firm-leadership>

      <firm-sustainability :firm="firm"></firm-sustainability>
    </div>
  </div>
</template>

<script>
import FirmAbout from 'vue-app/scout/shared/law-firms/firm-about.vue';
import FirmPracticeAreas from 'vue-app/scout/shared/law-firms/firm-practice-areas.vue';
import FirmOfficeLocations from 'vue-app/scout/shared/law-firms/firm-office-locations.vue';
import FirmDiversity from 'vue-app/scout/shared/law-firms/firm-diversity.vue';
import FirmRepresentativeMatters from 'vue-app/scout/shared/law-firms/firm-representative-matters.vue';
import FirmRelationshipPartners from 'vue-app/scout/client/law-firms/firm-relationship-partners.vue';
import FirmAwards from 'vue-app/scout/shared/law-firms/firm-awards.vue';
import FirmLeadership from 'vue-app/scout/shared/law-firms/firm-leadership.vue';
import FirmHighlights from 'vue-app/scout/shared/law-firms/firm-highlights.vue';
import FirmSustainability from 'vue-app/scout/shared/law-firms/firm-sustainability.vue';
import FirmStatus from 'vue-app/scout/client/law-firms/firm-status.vue';
import DataDisclaimerBox from 'vue-app/scout/shared/data-disclaimer-box.vue';
import displayRules from 'vue-app/shared/mixins/display-rules';

export default {
  name: 'FirmOverview',

  components: {
    FirmAbout,
    FirmPracticeAreas,
    FirmOfficeLocations,
    FirmDiversity,
    FirmLeadership,
    FirmRepresentativeMatters,
    FirmRelationshipPartners,
    FirmAwards,
    FirmHighlights,
    FirmSustainability,
    FirmStatus,
    DataDisclaimerBox
  },

  mixins: [displayRules],

  props: {
    firm: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      showFirmAbout: false,
      showFirmDiversity: false,
      showFirmRepresentativeMatters: false
    };
  },

  computed: {
    showDataDisclaimerBox() {
      const noAbout = !this.showFirmAbout;
      const noDiversity = !this.showFirmDiversity;
      const noRepresentativeMatters = !this.showFirmRepresentativeMatters;
      const noLocations = !this.hasLocations;
      const noPracticeAreas = !this.hasPracticeAreas;

      return noAbout && noDiversity && noRepresentativeMatters && noLocations && noPracticeAreas;
    },

    hasLocations() {
      return this.anyDataIsPresent([this.firm.locations]);
    },

    hasPracticeAreas() {
      return this.anyDataIsPresent([this.firm.practiceAreas]);
    }
  },

  mounted() {
    this.evaluateComponentDisplay();
  },

  methods: {
    evaluateComponentDisplay() {
      if (this.$refs.firmAbout) {
        this.showFirmAbout = this.$refs.firmAbout.displayComponent();
      }
      if (this.$refs.firmDiversity) {
        this.showFirmDiversity = this.$refs.firmDiversity.displayComponent();
      }
      if (this.$refs.firmRepresentativeMatters) {
        this.showFirmRepresentativeMatters = this.$refs.firmRepresentativeMatters.displayComponent();
      }
    }
  }
};
</script>
