<template>
  <priori-modal ref="addFirmModal" modal-id="add-firm-modal" title="Add Law Firm" @on-modal-close="clearForm">
    <template #modal-trigger="{ openModal }">
      <button type="button" class="secondary-button-compact" @click="openModal">+ Add Firm</button>
    </template>

    <div class="bottom-30">
      <loading-section name="rfqAvailableFirms">
        <form role="form" class="a-form bottom-30" novalidate>
          <typeahead-vertical
            ref="selectFirmTypeahead"
            label="Select a law firm from your team’s connected group"
            rules="required"
            :id="'add-firm'"
            :force-select="true"
            :placeholder="'Search by name'"
            :options="availableFirmNames"
            @input="addFirmToQuotes">
          </typeahead-vertical>
        </form>

        <div v-if="hasAddedLawFirmQuotes">
          <tag-list
            :tags="lawFirmQuotesToAdd"
            :on-remove="removeFirmFromQuotes"
            :value-fn="getFirmName"
            tag-class="clear">
          </tag-list>
        </div>
      </loading-section>
    </div>

    <div class="row tight-columns">
      <div class="col-sm-4">
        <button type="button" :class="['primary-btn-blue', { 'disabled': !lawFirmQuotesChanged }]" @click="proceedToAddFirm">Add Firm</button>
      </div>

      <div class="col-sm-3 top-20-xs">
        <button type="button" class="nv-button-white" @click="closeModal">Cancel</button>
      </div>
    </div>
  </priori-modal>
</template>

<script>
import PrioriModal from 'vue-app/shared/components/priori-modal.vue';
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import TagList from 'vue-app/scout/shared/tag-list.vue';
import { isEqual } from 'lodash';

export default {
  name: 'RfqAddFirm',

  components: {
    PrioriModal,
    TypeaheadVertical,
    LoadingSection,
    TagList
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    availableFirms: {
      type: Array,
      required: true,
      default: () => []
    }
  },

  data() {
    return {
      lawFirmQuotesToAdd: []
    };
  },

  computed: {
    lawFirmQuotesChanged() {
      return !isEqual(this.rfq.lawFirmQuotes, this.lawFirmQuotesToAdd);
    },

    availableFirmNames() {
      return this.availableFirms.filter(firm => {
        return !this.lawFirmQuotesToAdd.some(quote => quote.lawFirmId === firm.id);
      }).map(firm => firm.name);
    },

    hasAddedLawFirmQuotes() {
      return this.lawFirmQuotesToAdd.length > 0;
    }
  },

  methods: {
    addFirmToQuotes(firmName) {
      if (firmName === null || firmName === '') { return; }

      const newFirm = this.availableFirms.find(obj => obj.name === firmName);

      if (newFirm) {
        this.lawFirmQuotesToAdd.push(
          {
            id: null,
            firmHasRfqEnabled: true,
            firmName: newFirm.name,
            lawFirmId: newFirm.id,
            suggestedLawyers: []
          }
        );
      }

      this.$refs.selectFirmTypeahead.reset();
    },

    getFirmName(firm) {
      return firm.firmName;
    },

    removeFirmFromQuotes(index) {
      this.lawFirmQuotesToAdd.splice(index, 1);
      this.$refs.selectFirmTypeahead.reset();
    },

    proceedToAddFirm() {
      this.rfq.lawFirmQuotes.push(...this.lawFirmQuotesToAdd);
      this.closeModal();
    },

    closeModal() {
      this.clearForm();
      this.$refs.addFirmModal.closeModal();
    },

    clearForm() {
      this.lawFirmQuotesToAdd = [];
      this.$refs.selectFirmTypeahead.reset();
    }
  }
};
</script>
