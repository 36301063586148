<template>
  <div class="titlebar lawyer-titlebar">
    <div class="row tight-columns">
      <div class="col-sm-6">
        <div class="medium-page-heading text-ellipsis">
          <span class="vertical-middle right-15">{{ matter.name }}</span>

          <span :class="['status-pill vertical-middle', { 'closed': matter.closedOn }]">{{ matter.closedOn ? 'Closed' : 'Open' }}</span>
        </div>

        <div class="pill-gray-text text-italic" v-if="matter.code">
          #{{ matter.code }}
        </div>

        <div class="top-5">
          <span class="semibold-weight">Manager:</span> {{ managerNames }}
        </div>
      </div>

      <div class="col-sm-6">
        <div class="row tight-columns">
          <div class="col-sm-6 col-lg-7 top-20-xs">
            <send-review-reminder-modal
              v-if="currentUserIsAdmin"
              :participants="matter.matterParticipants"
              :send-review-reminders="sendReviewReminders">
            </send-review-reminder-modal>
          </div>

          <div class="col-sm-6 col-lg-5 top-10-xs">
            <review-form-guide-modal
              :matter-id="matter.id"
              :preselected-firm="preselectedFirm"
              :load-firms="loadReviewableFirms"
              :load-firm-lawyers="loadReviewableLawyers">
              <template #trigger="{ openAndLoadFirms }">
                <button type="button" class="secondary-btn-blue" @click="openAndLoadFirms">
                  Leave a Review
                </button>
              </template>
            </review-form-guide-modal>
          </div>
        </div>
      </div>
    </div>

    <div class="top-10 bottom-10">
      <hr class="no-margin">
    </div>

    <div class="row">
      <div class="col-sm-3 col-md-2">
        <div class="bold-weight text-uppercase">
          Opened
        </div>

        <div>
          {{ formattedDate(matter.openedOn) }}
        </div>
      </div>

      <div class="col-sm-3 col-md-2 top-10-xs">
        <div class="bold-weight text-uppercase">
          Closed
        </div>

        <div :class="{ 'missing-text': !matter.closedOn }">
          {{ formattedDate(matter.closedOn) || 'Active' }}
        </div>
      </div>

      <div class="col-sm-3 col-md-2 top-10-xs">
        <div class="bold-weight text-uppercase">
          Total Spent
        </div>

        <div>
          {{ formattedFee(matter.feeTotalInUsCents) }}
        </div>
      </div>

      <div class="col-sm-3 col-md-6 top-10-xs">
        <div class="bold-weight text-uppercase">
          Internal Practice Area
        </div>

        <div class="tag-list top-2" v-if="matter.practiceArea">
          <div class="tag-list-item gray compact">
            {{ matter.practiceAreaFullPath || matter.practiceArea }}
          </div>
        </div>

        <div v-if="!matter.practiceArea">&mdash;</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState }            from 'pinia';
import useCurrentUserStore     from 'vue-app/stores/current-user.js';
import DateFilter              from 'vue-app/shared/lib/date-filter';
import matterFeeFormatter      from 'vue-app/shared/mixins/matter-fee-formatter.js';
import ReviewFormGuideModal    from 'vue-app/scout/client/reviews/review-form-guide-modal.vue';
import SendReviewReminderModal from 'vue-app/scout/client/matters/send-review-reminder-modal.vue';
import { sortBy }              from 'lodash';

export default {
  name: 'MatterTitlebar',

  components: {
    ReviewFormGuideModal,
    SendReviewReminderModal
  },

  mixins: [matterFeeFormatter],

  props: {
    matter: {
      type:     Object,
      required: true
    },

    sendReviewReminders: {
      type:     Function,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentUser: 'currentUser' }),

    currentUserIsAdmin() {
      return this.currentUser.admin;
    },

    managerNames() {
      return this.managersExist ? sortBy(this.matter.managerFullNames).join(', ') : '—';
    },

    managersExist() {
      return this.matter.managerFullNames.length > 0;
    },

    preselectedFirm() {
      if (this.matter.scoutMatterLawFirms.length === 1) {
        return this.matter.scoutMatterLawFirms[0];
      }
    }
  },

  methods: {
    loadReviewableFirms() {
      return Promise.resolve(this.matter.scoutMatterLawFirms);
    },

    loadReviewableLawyers(lawFirmBridgeId) {
      const lawyers = this.matter.scoutMatterLawyers
        .map(matterLawyer => matterLawyer.lawyer)
        .filter(lawyer => lawyer.lawFirmBridgeId === lawFirmBridgeId);

      return Promise.resolve(lawyers);
    },

    formattedDate(date) {
      return DateFilter.filterDate(date);
    }
  }
};
</script>
