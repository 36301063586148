<template>
  <div>
    <rfq-stepper @step-change="handleStepChange">
      <template #default="{ activeStep: activeStep, moveToNextStep: moveToNextStep, moveToPrevStep: moveToPrevStep }">
        <rfq-step-recipients
          v-if="activeStep == 1"
          :next-step="{moveToNextStep}"
          :prev-step="{moveToPrevStep}"
          :rfq="rfq"
          :resource-lists="resourceLists"
          :saved-searches="savedSearches"
          :available-firms="availableFirms"
          :is-feature-lab="currentProduct.isFeatureLab"
          :marketplace-rfqs-enabled="marketplaceRfqsEnabled"
          :on-destroy-all-law-firm-quotes="onDestroyAllLawFirmQuotes"
          :on-save="onSave"
          @contact-admins="onContactAdmins"
          @firms-enabled-changed="onFirmsEnabledChanged">
        </rfq-step-recipients>

        <rfq-step-project-details
          v-if="activeStep == 2"
          :next-step="{moveToNextStep}"
          :prev-step="{moveToPrevStep}"
          :rfq="rfq"
          :on-update-supporting-documents="onUpdateSupportingDocuments"
          :on-save="onSave">
        </rfq-step-project-details>

        <rfq-step-questions
          v-if="activeStep == 3"
          :next-step="{moveToNextStep}"
          :prev-step="{moveToPrevStep}"
          :rfq="rfq"
          :on-save="onSave">
        </rfq-step-questions>

        <rfq-step-review
          v-if="activeStep == 4"
          :next-step="{moveToNextStep}"
          :prev-step="{moveToPrevStep}"
          :rfq="rfq">
        </rfq-step-review>
      </template>
    </rfq-stepper>
  </div>
</template>

<script>
import RfqStepper from 'vue-app/scout/client/rfqs/create/stepper.vue';
import RfqStepRecipients from 'vue-app/scout/client/rfqs/create/step-recipients.vue';
import RfqStepProjectDetails from 'vue-app/scout/client/rfqs/create/step-project-details.vue';
import RfqStepQuestions from 'vue-app/scout/client/rfqs/create/step-questions.vue';
import RfqStepReview from 'vue-app/scout/client/rfqs/create/step-review.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'RfqCreateForm',

  components: {
    RfqStepper,
    RfqStepRecipients,
    RfqStepProjectDetails,
    RfqStepQuestions,
    RfqStepReview
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    resourceLists: {
      type: Array,
      required: true
    },

    savedSearches: {
      type: Array,
      required: true
    },

    availableFirms: {
      type: Array,
      required: true
    },

    onUpdateSupportingDocuments: {
      type: Function,
      required: true
    },

    onDestroyAllLawFirmQuotes: {
      type: Function,
      required: true
    },

    onSave: {
      type: Function,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, { currentProduct: 'currentProduct', user: 'currentUser' }),

    marketplaceRfqsEnabled() {
      return this.user.workspace.marketplaceRfqsEnabled;
    }
  },

  methods: {
    handleStepChange(params) {
      this.$emit('step-change', { newActiveStep: params.newActiveStep, rfq: params.rfq });
    },

    onContactAdmins(data) {
      this.$emit('contact-admins', data);
    },

    onFirmsEnabledChanged(firmsEnabled) {
      this.$emit('firms-enabled-changed', firmsEnabled);
    }
  }
};
</script>
