<template>
  <validation-observer v-slot="{ handleSubmit, failed }">
    <form class="a-form" novalidate>
      <div v-if="isEditing" class="bottom-30">
        <text-input-vertical
          id="nameForLawyersReference"
          label="Project Name"
          rules="required"
          v-model="requestCopy.nameForLawyersReference">
        </text-input-vertical>
      </div>

      <div v-if="!requestCopy.fromInteractive() && isEditing">
        <div class="bottom-30">
          <text-input-vertical
            id="oldRequestDescription"
            label="Project Description"
            rules="required"
            v-model="requestCopy.requestDescription">
          </text-input-vertical>
        </div>

        <div class="bottom-30 state-container">
          <typeahead-vertical
            id="state"
            :initial-value="requestCopy.state"
            label="State"
            :options="stateNames"
            placeholder="Enter state"
            :hide-icon="true"
            v-model="requestCopy.state">
          </typeahead-vertical>
        </div>
      </div>

      <div v-if="requestCopy.fromInteractive() || !isEditing">
        <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
          <div class="bottom-30" v-if="!isEditing">
            <div class="bottom-10">
              Please select your legal need and complete the form to submit a new RFP. Once submitted, we will review the RFP and send you a custom proposal with lawyers from our vetted attorney network that match your project requirements. This typically occurs within 1-3 business days and you will be notified by email when your matches are ready.
            </div>

            <div>
              If you would prefer to use our interactive RFP builder, <a href="/public-app/rfp" target="_blank">click here</a>.
            </div>
          </div>

          <hr class="kenny-hr no-margin">

          <div class="top-30">
            <label>Legal Need <span class="red-text size-text-14px">*</span></label>

            <div :class="['box-select', 'bottom-10', { 'selected': requestCopy.projectType === 'advisory' }]" @click="onSelectType('advisory')">
              <div class="semibold-weight">
                Project
              </div>

              <div class="dark-gray-text">
                Lawyer or law firm for specialized project, transaction, local legal expertise or litigation.
              </div>
            </div>

            <div :class="['box-select', { 'selected': requestCopy.projectType === 'overflow' }]" @click="onSelectType('overflow')">
              <div class="semibold-weight">
                People
              </div>

              <div class="dark-gray-text">
                Junior or senior legal professional for overflow work or a temporary on- or off-site placement.
              </div>
            </div>

            <input type="hidden" v-model="requestCopy.projectType">
          </div>

          <div v-if="errors.length" class="error-text top-5">
            <error-message :errors="errors"></error-message>
          </div>
        </validation-provider>

        <div class="top-30" v-if="requestCopy.projectType">
          <div class="bottom-20">
            <text-input-vertical
              id="companyDescription"
              label="General Company Description"
              :maxlength="50"
              placeholder="e.g. &quot;print media company&quot; (Limit: 50 Characters)"
              autocomplete="off"
              rules="required"
              v-model="requestCopy.companyDescription">
              <template #description>
                <div v-if="!isEditing" class="dark-gray-text text-italic bottom-15">
                  This general company description is displayed on our platform and may be provided to prospective attorney matches for your project. We ask for a general description to anonymize and protect your company’s identity during the RFP process.
                </div>
              </template>
            </text-input-vertical>

            <div class="top-5 text-right dark-gray-text">
              <i class="size-text-12px">{{ requestCopy.companyDescription ? requestCopy.companyDescription.length : 0 }} / 50 Characters</i>
            </div>
          </div>

          <div class="bottom-15">
            <label>Company Location</label>

            <div class="row tight-columns">
              <div class="col-sm-4 bottom-15">
                <typeahead-vertical
                  id="company-location-country"
                  :initial-value="requestCopy.country"
                  @input="resetCompanyState"
                  :options="lists.countries"
                  placeholder="Country"
                  :hide-icon="true"
                  v-model="requestCopy.country">
                </typeahead-vertical>
              </div>

              <div class="col-sm-4 bottom-15 company-location-state-container">
                <typeahead-vertical
                  :disabled="stateDisabled"
                  :hide-icon="true"
                  id="company-location-state"
                  :initial-value="requestCopy.state"
                  :options="stateNames"
                  :placeholder="statePlaceHolder(requestCopy.country)"
                  ref="companyLocationState"
                  v-model="requestCopy.state">
                </typeahead-vertical>
              </div>

              <div class="col-sm-4 bottom-15">
                <text-input-vertical
                  id="company-location-city"
                  autocomplete="nope"
                  placeholder="City"
                  v-model="requestCopy.city">
                </text-input-vertical>
              </div>
            </div>
          </div>

          <div class="bottom-30" v-if="requestCopy.isPeopleType()">
            <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
              <label>Legal Professional <span class="red-text size-text-14px">*</span></label>

              <dropdown-select
                id-label="professionalType"
                key="people-dropdown"
                :initial-label="requestCopy.professionalType"
                :options="['Lawyer', 'Paralegal', 'Legal Operations Professional']"
                v-model="requestCopy.professionalType">
              </dropdown-select>

              <div class="error-text top-5" v-if="errors.length">
                <error-message :errors="errors"></error-message>
              </div>
            </validation-provider>
          </div>

          <div class="bottom-30" v-if="(firmsEnabled || isEditing) && !requestCopy.isPeopleType()">
            <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
              <label>Lawyer/Law Firm <span class="red-text size-text-14px">*</span></label>

              <dropdown-select
                id-label="professionalType"
                key="project-dropdown"
                :initial-label="requestCopy.professionalType"
                :options="['Lawyer', 'Law Firm']"
                v-model="requestCopy.professionalType"
                @change="setElementsToNotCloseOn">
              </dropdown-select>

              <div class="error-text top-5" v-if="errors.length">
                <error-message :errors="errors"></error-message>
              </div>
            </validation-provider>
          </div>

          <div class="bottom-20" v-if="requestCopy.wantsFirm()">
            <div class="semibold-weight bottom-15">
              Office Location
            </div>

            <div v-for="(location, index) in requestCopy.lawFirmOfficeLocations" :key="`location-${index}`" class="deselect-input">
              <div class="row tight-columns">
                <!-- TODO: interaction between country state is wrong (mixed with main company location) -->
                <div :class="`col-sm-4 bottom-15 office-location-country-container-${index}`">
                  <typeahead-vertical
                    :id="`office-location-country-${index}`"
                    :initial-value="location.country"
                    :options="lists.countries"
                    placeholder="Country"
                    :hide-icon="true"
                    v-model="location.country">
                  </typeahead-vertical>
                </div>

                <div :class="`col-sm-4 bottom-15 office-location-state-container-${index}`">
                  <typeahead-vertical
                    :disabled="stateDisabled"
                    :id="`office-location-state-${index}`"
                    :initial-value="location.state"
                    :options="stateNames"
                    :placeholder="statePlaceHolder(location.country)"
                    :hide-icon="true"
                    v-model="location.state">
                  </typeahead-vertical>
                </div>

                <div class="col-sm-4 bottom-15">
                  <text-input-vertical
                    autocomplete="nope"
                    :id="`office-location-city-${index}`"
                    placeholder="City"
                    v-model="location.city">
                  </text-input-vertical>

                  <div @click="removeOfficeLocation(index)">
                    <svg-icon class="deselect-icon for-tight-column-input" name="x3"></svg-icon>
                  </div>
                </div>
              </div>
            </div>

            <div class="row tight-columns">
              <div class="col-sm-6 col-sm-push-6 text-right">
                <div class="semibold-weight" v-if="requestCopy.lawFirmOfficeLocations.length > 1">
                  <a href="" :class="{ 'underlined': requestCopy.lawFirmOfficeLogic === 'and', 'gray-text': requestCopy.lawFirmOfficeLogic !== 'and' }" @click.prevent="updateLogic('lawFirmOfficeLogic', 'and')">And</a> | <a href="" :class="{ 'underlined': requestCopy.lawFirmOfficeLogic === 'or', 'gray-text': requestCopy.lawFirmOfficeLogic !== 'or' }" @click.prevent="updateLogic('lawFirmOfficeLogic', 'or')">Or</a>
                </div>
              </div>

              <div class="col-sm-6 col-sm-pull-6 top-20-xs">
                <button type="button" class="secondary-btn-blue smaller" :disabled="requestCopy.hasInvalidOfficeLocations()" @click="addOfficeLocation">Add Office Location</button>
              </div>
            </div>
          </div>

          <div class="bottom-30" v-if="!requestCopy.wantsFirm()">
            <label>Requested Experience Level</label>

            <dropdown-select
              :allow-null="true"
              id-label="experienceYears"
              :initial-label="requestCopy.experienceYears"
              key="experience-years-dropdown"
              :options="lists.yearsExperience"
              placeholder="Select experience level"
              v-model="requestCopy.experienceYears">
            </dropdown-select>
          </div>

          <div v-if="requestCopy.professionalType === 'Lawyer'" class="bottom-30">
            <label>Bar License</label>

            <div v-for="(bar, index) in requestCopy.requiredBars" :class="`deselect-input bar-country-container-${index}`" :key="`required-bar-${index}`">
              <div class="row tight-columns">
                <div class="col-sm-6 bottom-15">
                  <typeahead-vertical
                    :disabled="index !== requestCopy.requiredBars.length - 1"
                    :id="`bar-country-${index}`"
                    :initial-value="bar.country"
                    :options="barCountryNames"
                    placeholder="Enter country"
                    :hide-icon="true"
                    v-model="bar.country"
                    @input="updateBars">
                  </typeahead-vertical>
                </div>

                <div :class="`col-sm-6 bottom-15 bar-state-container-${index}`">
                  <typeahead-vertical
                    :disabled="index !== requestCopy.requiredBars.length - 1 || bar.country !== 'United States'"
                    :id="`bar-state-${index}`"
                    :initial-value="bar.state"
                    :options="barStateNames"
                    :placeholder="statePlaceHolder(bar.country)"
                    :hide-icon="true"
                    v-model="bar.state"
                    @input="updateBars">
                  </typeahead-vertical>

                  <div @click="removeBar(bar, index)">
                    <svg-icon class="deselect-icon for-tight-column-input" name="x3"></svg-icon>
                  </div>
                </div>
              </div>
            </div>

            <div class="row tight-columns">
              <div v-if="hasMoreThanOneRequiredBar" class="col-sm-6 col-sm-push-6 semibold-weight text-right">
                <a href="" :class="{ 'underlined': requestCopy.barLogic === 'and', 'gray-text': requestCopy.barLogic !== 'and' }" @click.prevent="updateLogic('barLogic', 'and')">And</a> | <a href="" :class="{ 'underlined': requestCopy.barLogic === 'or', 'gray-text': requestCopy.barLogic !== 'or' }" @click.prevent="updateLogic('barLogic', 'or')">Or</a>
              </div>

              <div :class="['col-sm-6', { 'col-sm-pull-6 top-20-xs': hasMoreThanOneRequiredBar }]">
                <button type="button" :disabled="!allBarsValid" class="secondary-btn-blue smaller" @click="addBar">Add Bar License</button>
              </div>
            </div>
          </div>

          <div class="bottom-30 practice-areas-container">
            <loading-section name="rfpProficiency" :render-after-loading="true">
              <div class="overlay-container">
                <typeahead-vertical
                  ref="requestedExperience"
                  label="Requested Experience"
                  id="practice-areas"
                  placeholder="Type to search experience"
                  v-model="practiceArea"
                  :options="practiceAreas"
                  option-label-key="label"
                  option-value-key="name"
                  :hide-icon="true"
                  :allow-enter-keydown="true"
                  :force-select="true"
                  @enterKeydown="addPracticeArea"
                  @input="addPracticeArea">
                  <template #description>
                    <div v-if="!isEditing" class="dark-gray-text text-italic bottom-15">
                      Enter your requested experience (e.g. vendor agreements). You can add as many as you like. You will also be able to enter a brief description of the required work below.
                    </div>
                  </template>
                </typeahead-vertical>
              </div>
            </loading-section>

            <div v-if="requestCopy.practiceAreas.length" class="tag-list top-10">
              <div
                v-for="(experience, index) in requestCopy.practiceAreas"
                class="tag-list-item green delete-variant"
                :key="experience">
                <span class="right-10">{{ experience }}</span>
                <a href="" class="tag-list-item-delete" @click.prevent="removePracticeArea(index)"><svg-icon name="x-circle" class="base-icon"></svg-icon></a>
              </div>
            </div>
          </div>

          <div v-if="requestCopy.projectType === 'overflow'">
            <div v-if="isEditing" class="bottom-20">
              <text-input-vertical
                autocomplete="nope"
                id="duration"
                label="Duration of Engagement"
                placeholder="Duration"
                v-model="requestCopy.duration">
                <template #labelDescription>
                  <span class="label-modifier">(Pluralize time unit if greater than 1 to ensure proper display in RFP description)</span>
                </template>
              </text-input-vertical>

              <label class="top-10 check-option">
                Ongoing engagement <span class="normal-weight">(no set end date)</span>
                <input type="checkbox" name="isOngoing" id="isOngoing" v-model="requestCopy.isOngoing">
                <span class="check c-box"></span>
              </label>
            </div>

            <div v-else class="bottom-20">
              <label for="durationNumberLower">Duration of Engagement</label>

              <div class="row tight-columns bottom-10">
                <div class="col-sm-8">
                  <div class="row tight-columns">
                    <div class="col-xs-5 bottom-10">
                      <validation-provider :rules="minRangeRules('durationNumberUpper')" :mode="passiveAggressive" v-slot="{ errors }">
                        <input
                          type="number"
                          name="durationNumberLower"
                          id="durationNumberLower"
                          :class="['form-control', { 'has-error': errors.length }]"
                          min="1"
                          step="1"
                          placeholder="From"
                          :disabled="requestCopy.isOngoing"
                          v-model="requestCopy.durationNumberLower">

                        <div class="error-text top-5" v-if="errors.length">
                          <error-message :errors="errors"></error-message>
                        </div>
                      </validation-provider>
                    </div>

                    <div class="col-xs-2 text-center bottom-10">
                      <div class="top-10">&mdash;</div>
                    </div>

                    <div class="col-xs-5 bottom-10">
                      <validation-provider :rules="optionalMaxRangeRules('durationNumberLower')" :mode="passiveAggressive" v-slot="{ errors }">
                        <input
                          type="number"
                          name="durationNumberUpper"
                          id="durationNumberUpper"
                          :class="['form-control', { 'has-error': errors.length }]"
                          min="1"
                          step="1"
                          placeholder="To (Optional)"
                          :disabled="requestCopy.isOngoing"
                          v-model="requestCopy.durationNumberUpper">

                        <div class="error-text top-5" v-if="errors.length">
                          <error-message :errors="errors"></error-message>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4 bottom-10">
                  <dropdown-select
                    :allow-null="true"
                    id-label="durationUnit"
                    :initial-label="requestCopy.durationUnit"
                    key="duration-unit"
                    :is-disabled="requestCopy.isOngoing"
                    :options="['Days', 'Weeks', 'Months']"
                    v-model="requestCopy.durationUnit">
                  </dropdown-select>
                </div>
              </div>

              <label class="check-option">
                Ongoing engagement <span class="normal-weight">(no set end date)</span>
                <input type="checkbox" name="isOngoing" id="isOngoing" v-model="requestCopy.isOngoing">
                <span class="check c-box"></span>
              </label>
            </div>

            <div class="bottom-20">
              <label>Start Date</label>

              <div class="row tight-columns">
                <div class="col-sm-6 bottom-10">
                  <dropdown-select
                    :allow-null="true"
                    id-label="isAsap"
                    :initial-label="isAsapString"
                    key="start-date-dropdown"
                    :options="['As soon as possible', 'Specific date']"
                    v-model="isAsapString"
                    @change="setIsAsap">
                  </dropdown-select>
                </div>

                <div class="col-sm-6" v-if="requestCopy.startDateType === 'day'">
                  <validation-provider rules="required" :mode="passiveAggressive" v-slot="{ errors }">
                    <div class="input-group datepicker-group">
                      <input
                        type="text"
                        id="startDate"
                        name="startDate"
                        :class="['form-control', { 'has-error': errors.length }]"
                        placeholder="Start date"
                        autocomplete="nope"
                        @click="toggleRenderDatePicker"
                        :value="formattedDate(requestCopy.startDate)">
                      <span class="input-group-btn">
                        <button type="button" :class="['btn', 'btn-default', { 'date-picker-error': errors.length }]" @click="toggleRenderDatePicker">
                          <svg-icon class="button-icon" name="calendar"></svg-icon>
                        </button>
                      </span>
                    </div>

                    <div v-if="errors.length" class="error-text top-5">
                      <error-message :errors="errors"></error-message>
                    </div>
                  </validation-provider>

                  <styled-date-picker
                    v-if="renderDatePicker"
                    class="styled-date-picker top-5"
                    :date="formattedDate(requestCopy.startDate)"
                    @input="setStartDate">
                  </styled-date-picker>
                </div>
              </div>
            </div>
          </div>

          <div class="bottom-30">
            <textarea-input-vertical
              id="work-description"
              label="Brief Description of Work"
              name="description"
              placeholder="Enter description of work required"
              :rows="4"
              v-model="requestCopy.requestDescription">
            </textarea-input-vertical>
          </div>

          <div v-if="requestCopy.projectType === 'overflow'">
            <div class="bottom-30">
              <dropdown-select
                :allow-null="true"
                id-label="timeCommitment"
                :initial-label="requestCopy.timeCommitment"
                key="time-commitment"
                label="Weekly Time Commitment"
                :options="['Part-time', 'Full-time', 'As needed']"
                v-model="requestCopy.timeCommitment">
              </dropdown-select>
            </div>

            <div v-if="requestCopy.timeCommitment === 'Part-time' && isEditing" class="bottom-20">
              <text-input-vertical
                id="timeCommitmentDetails"
                label="How Much Time Per Week"
                placeholder="Time Commitment"
                v-model="requestCopy.timeCommitmentDetails">
                <template #labelDescription>
                  <span class="label-modifier">(Pluralize time unit if greater than 1 to ensure proper display in RFP description)</span>
                </template>
              </text-input-vertical>
            </div>

            <div class="bottom-20" v-if="requestCopy.timeCommitment === 'Part-time' && !isEditing">
              <label for="timeCommitmentDetailsNumberLower">How Much Time Per Week?</label>

              <div class="row tight-columns">
                <div class="col-sm-8">
                  <div class="row tight-columns">
                    <div class="col-xs-5 bottom-10">
                      <validation-provider :rules="minRangeRules('timeCommitmentDetailsNumberUpper')" :mode="passiveAggressive" v-slot="{ errors }">
                        <input
                          :class="['form-control', { 'has-error': errors.length }]"
                          id="timeCommitmentDetailsNumberLower"
                          min="1"
                          name="timeCommitmentDetailsNumberLower"
                          placeholder="From"
                          step="1"
                          type="number"
                          v-model="requestCopy.timeCommitmentDetailsNumberLower">

                        <div class="error-text top-5" v-if="errors.length">
                          <error-message :errors="errors"></error-message>
                        </div>
                      </validation-provider>
                    </div>

                    <div class="col-xs-2 text-center bottom-10">
                      <div class="top-10">&mdash;</div>
                    </div>

                    <div class="col-xs-5 bottom-10">
                      <validation-provider :rules="optionalMaxRangeRules('timeCommitmentDetailsNumberLower')" :mode="passiveAggressive" v-slot="{ errors }">
                        <input
                          :class="['form-control', { 'has-error': errors.length }]"
                          id="timeCommitmentDetailsNumberUpper"
                          min="1"
                          name="timeCommitmentDetailsNumberUpper"
                          placeholder="To (Optional)"
                          step="1"
                          type="number"
                          v-model="requestCopy.timeCommitmentDetailsNumberUpper">

                        <div class="error-text top-5" v-if="errors.length">
                          <error-message :errors="errors"></error-message>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4 bottom-10">
                  <dropdown-select
                    :allow-null="true"
                    id-label="timeCommitmentDetailsUnit"
                    key="time-commitment-details-unit"
                    :initial-label="requestCopy.timeCommitmentDetailsUnit"
                    :options="['Hours', 'Days']"
                    v-model="requestCopy.timeCommitmentDetailsUnit">
                  </dropdown-select>
                </div>
              </div>
            </div>
          </div>

          <div v-if="requestCopy.projectType === 'overflow'">
            <div class="bottom-30">
              <dropdown-select
                :allow-null="true"
                id-label="isOnsite"
                :initial-label="labelForSlug(lists.isOnsiteOptions, requestCopy.isOnsite)"
                key="required-location"
                label="Required Location"
                label-key="label"
                :options="lists.isOnsiteOptions"
                value-key="slug"
                v-model="requestCopy.isOnsite">
              </dropdown-select>
            </div>

            <div v-if="requestCopy.isOnsite === 'yes' && isEditing" class="bottom-20">
              <text-input-vertical
                id="onsiteDetails"
                label="How Much Time On-Site Per Week?"
                placeholder="Onsite Duration"
                v-model="requestCopy.onsiteDetails">
                <template #labelDescription>
                  <span class="label-modifier">(Pluralize time unit if greater than 1 to ensure proper display in RFP description)</span>
                </template>
              </text-input-vertical>
            </div>

            <div v-if="requestCopy.isOnsite === 'yes' && !isEditing" class="bottom-20">
              <label for="onsiteDetailsNumberLower">How Much Time On-Site Per Week?</label>

              <div class="row tight-columns">
                <div class="col-sm-8">
                  <div class="row tight-columns">
                    <div class="col-xs-5 bottom-10">
                      <validation-provider :rules="minRangeRules('onsiteDetailsNumberUpper')" :mode="passiveAggressive" v-slot="{ errors }">
                        <input
                          class="form-control"
                          id="onsiteDetailsNumberLower"
                          min="1"
                          name="onsiteDetailsNumberLower"
                          placeholder="From"
                          step="1"
                          type="number"
                          v-model="requestCopy.onsiteDetailsNumberLower">

                        <div class="error-text top-5" v-if="errors.length">
                          <error-message :errors="errors"></error-message>
                        </div>
                      </validation-provider>
                    </div>

                    <div class="col-xs-2 text-center bottom-10">
                      <div class="top-10">&mdash;</div>
                    </div>

                    <div class="col-xs-5 bottom-10">
                      <validation-provider :rules="optionalMaxRangeRules('onsiteDetailsNumberLower')" :mode="passiveAggressive" v-slot="{ errors }">
                        <input
                          class="form-control"
                          id="onsiteDetailsNumberUpper"
                          min="1"
                          name="onsiteDetailsNumberUpper"
                          placeholder="To (Optional)"
                          step="1"
                          type="number"
                          v-model="requestCopy.onsiteDetailsNumberUpper">

                        <div class="error-text top-5" v-if="errors.length">
                          <error-message :errors="errors"></error-message>
                        </div>
                      </validation-provider>
                    </div>
                  </div>
                </div>

                <div class="col-sm-4 bottom-10">
                  <dropdown-select
                    :allow-null="true"
                    id-label="onsiteDetailsUnit"
                    key="onsite-detail-unit"
                    :initial-label="requestCopy.onsiteDetailsUnit"
                    :options="['Hours', 'Days']"
                    v-model="requestCopy.onsiteDetailsUnit">
                  </dropdown-select>
                </div>
              </div>
            </div>

            <div v-if="requestCopy.isOnsite === 'yes'" class="bottom-30">
              <text-input-vertical
                id="onsiteLocation"
                label="On Site Location"
                placeholder="Enter office address"
                v-model="requestCopy.onsiteLocation">
              </text-input-vertical>
            </div>
          </div>

          <div v-if="requestCopy.wantsFirm()" class="bottom-20">
            <div class="semibold-weight bottom-15">
              Firm Accreditations
            </div>

            <dropdown ref="firmAccreditationsDropdown" class="k-dropdown accreditation-dropdown" v-model="firmAccreditationsDropdownOpen" :not-close-elements="elementsToNotCloseOn">
              <button type="button" role="button" class="dropdown-toggle placeholder">
                <span>Select all that apply</span>
                <svg-icon name="caret-down" class="caret-down"></svg-icon>
                <svg-icon name="caret-up" class="caret-up"></svg-icon>
              </button>

              <template #dropdown>
                <div class="menu-item-container" v-uiv-scrollable-dropdown="firmAccreditationsDropdownOpen">
                  <li v-for="option in lists.accreditationOptions" :key="option.name" class="menu-item" role="menuitem">
                    <div class="dropdown-row-inner semibold-weight">
                      <label class="check-option vertical">
                        {{ option.label }}
                        <input type="checkbox" :id="option.name" :value="option.name" v-model="requestCopy.firmAccreditations[option.name]">
                        <span class="check c-box"></span>
                      </label>
                    </div>
                  </li>
                </div>
              </template>
            </dropdown>

            <div class="top-10 bottom-20">
              <div v-if="requestCopy.selectedAccreditations().length" class="tag-list">
                <div
                  v-for="accreditation in requestCopy.selectedAccreditations()"
                  class="tag-list-item black"
                  :key="accreditation">
                  <span class="right-10">{{ accreditation }}</span>
                  <a href="" class="tag-list-item-delete" @click.prevent="requestCopy.deselectAccreditation(accreditation)"><svg-icon name="x-circle" class="base-icon"></svg-icon></a>
                </div>
              </div>
            </div>

            <div class="bottom-10">
              <input-checkbox
                id="preferenceFirmDiverse"
                name="preferenceFirmDiverse"
                v-model="requestCopy.preferenceFirmDiverse">
                <span class="semibold-weight">Firms with diverse partners</span>
              </input-checkbox>
            </div>

            <div v-if="requestCopy.preferenceFirmDiverse" class="bottom-10">
              <div class="row tight-columns">
                <div class="col-sm-7 top-10">
                  <label for="preferenceFirmDiversePercentage">Requested % of partners</label>
                </div>

                <div class="col-sm-5">
                  <div class="input-with-icon">
                    <input
                      class="form-control"
                      id="preferenceFirmDiversePercentage"
                      name="preferenceFirmDiversePercentage"
                      max="100"
                      min="1"
                      step="1"
                      type="number"
                      v-model="requestCopy.preferenceFirmDiversePercentage">
                    <div class="form-icon">%</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="bottom-10">
              <input-checkbox
                id="preferenceFirmVeterans"
                name="preferenceFirmVeterans"
                v-model="requestCopy.preferenceFirmVeterans">
                <span class="semibold-weight">Firms with partners who are veterans</span>
              </input-checkbox>
            </div>

            <div v-if="requestCopy.preferenceFirmVeterans" class="bottom-10">
              <div class="row tight-columns">
                <div class="col-sm-7 top-10">
                  <label for="preferenceFirmVeteransPercentage">Requested % of partners</label>
                </div>

                <div class="col-sm-5">
                  <div class="input-with-icon">
                    <input
                      class="form-control"
                      id="preferenceFirmVeteransPercentage"
                      max="100"
                      min="1"
                      name="preferenceFirmVeteransPercentage"
                      step="1"
                      type="number"
                      v-model="requestCopy.preferenceFirmVeteransPercentage">
                    <div class="form-icon">%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!requestCopy.wantsFirm()" class="bottom-20">
            <label>Additional Experience</label>

            <dropdown ref="additionalExperienceDropdown" class="k-dropdown experience-dropdown" v-model="additionalExperienceDropdownOpen" :not-close-elements="elementsToNotCloseOn">
              <button type="button" role="button" class="dropdown-toggle placeholder">
                <span class="dark-gray-text">Select all that apply</span>
                <svg-icon name="caret-down" class="caret-down"></svg-icon>
                <svg-icon name="caret-up" class="caret-up"></svg-icon>
              </button>

              <template #dropdown>
                <div class="menu-item-container" v-uiv-scrollable-dropdown="additionalExperienceDropdownOpen">
                  <li v-for="option in lists.experienceOptions" :key="option.name" class="menu-item" role="menuitem">
                    <div class="dropdown-row-inner semibold-weight">
                      <label class="check-option vertical">
                        {{ option.label }}
                        <input type="checkbox" :id="option.name" v-model="requestCopy[option.name]">
                        <span class="check c-box"></span>
                      </label>
                    </div>
                  </li>
                </div>
              </template>
            </dropdown>

            <div class="top-10 bottom-20">
              <div v-if="requestCopy.selectedExperiences().length" class="tag-list">
                <div
                  v-for="experience in requestCopy.selectedExperiences()"
                  class="tag-list-item black"
                  :key="experience">
                  <span class="right-10">{{ experience }}</span>
                  <a href="" class="tag-list-item-delete" @click.prevent="requestCopy.deselectExperience(experience)"><svg-icon name="x-circle" class="base-icon"></svg-icon></a>
                </div>
              </div>
            </div>

            <div class="bottom-10">
              <input-checkbox
                id="experienceDiverse"
                name="experienceDiverse"
                v-model="requestCopy.experienceDiverse">
                <span class="semibold-weight">Candidates who identify as diverse</span>
              </input-checkbox>
            </div>

            <div class="bottom-10">
              <input-checkbox
                id="experienceVeteran"
                name="experienceVeteran"
                v-model="requestCopy.experienceVeteran">
                <span class="semibold-weight">Candidates who are veterans</span>
              </input-checkbox>
            </div>
          </div>

          <div class="bottom-30">
            <div class="row tight-columns vertical-bottom-not-xs">
              <div class="col-sm-6 col-lg-5">
                <dropdown-select
                  :allow-null="false"
                  id-label="budgetFeeStructure"
                  :initial-label="budgetFeeInitialLabel"
                  key="budget-fee-structure"
                  label="Preferred Pricing Model"
                  label-key="label"
                  :options="lists.feeStructureOptions"
                  placeholder="Select pricing model"
                  value-key="slug"
                  v-model="requestCopy.budgetFeeStructure">
                </dropdown-select>
              </div>

              <div v-if="requestCopy.budgetFeeStructure === 'other'" class="col-sm-6 col-lg-7 top-10-xs">
                <text-input-vertical
                  id="feeStructureDescription"
                  placeholder="Enter details"
                  v-model="requestCopy.feeStructureDescription">
                </text-input-vertical>
              </div>
            </div>
          </div>

          <div class="bottom-20">
            <label for="budgetRangeLower">Budget Range</label>

            <div class="row tight-columns">
              <div class="col-xs-4 bottom-10">
                <validation-provider :rules="budgetLowerRangeRules" :mode="passiveAggressive" v-slot="{ errors }">
                  <div class="input-with-icon">
                    <input
                      :class="['form-control', { 'has-error': errors.length }]"
                      id="budgetRangeLower"
                      min="1"
                      name="budgetRangeLower"
                      placeholder="From"
                      step="1"
                      type="number"
                      v-model="requestCopy.budgetRangeLower">
                    <div class="form-icon">$</div>
                  </div>

                  <div class="error-text top-5" v-if="errors.length">
                    <error-message :errors="errors"></error-message>
                  </div>
                </validation-provider>
              </div>

              <div class="col-xs-1 text-center bottom-10">
                <div class="top-10">&mdash;</div>
              </div>

              <div class="col-xs-4 bottom-10">
                <validation-provider :rules="budgetRangeUpperRules" :mode="passiveAggressive" v-slot="{ errors }">
                  <div class="input-with-icon">
                    <input
                      :class="['form-control', { 'has-error': errors.length }]"
                      id="budgetRangeUpper"
                      min="1"
                      name="budgetRangeUpper"
                      placeholder="To"
                      step="1"
                      type="number"
                      v-model="requestCopy.budgetRangeUpper">
                    <div class="form-icon">$</div>
                  </div>

                  <div class="error-text top-5" v-if="errors.length">
                    <error-message :errors="errors"></error-message>
                  </div>
                </validation-provider>
              </div>

              <div class="col-xs-3 text-center top-10 bottom-10">
                <span v-if="requestCopy.budgetFeeStructure === 'per_hour' || requestCopy.budgetFeeStructure === 'per_week' || requestCopy.budgetFeeStructure === 'per_month'" class="semibold-weight">{{ labelForSlug(lists.feeStructureDisplayOptions, requestCopy.budgetFeeStructure) }}</span>
              </div>
            </div>

            <div class="top-5">
              <input-checkbox
                id="flexibleBudget"
                name="flexibleBudget"
                v-model="requestCopy.flexibleBudget">
                <span class="semibold-weight">Flexible budget</span>
              </input-checkbox>
            </div>
          </div>

          <div class="bottom-30">
            <textarea-input-vertical
              id="budgetDescription"
              label="Additional Budget Information"
              name="budgetDescription"
              placeholder="Please enter any additional budget information we should be aware of"
              :rows="4"
              v-model="requestCopy.budgetDescription">
            </textarea-input-vertical>
          </div>

          <div class="bottom-30">
            <textarea-input-vertical
              id="additionalPreferences"
              label="Any Additional Preferences"
              name="additionalPreferences"
              placeholder="Please let us know if you have any additional preferences for your attorney matches"
              :rows="4"
              v-model="requestCopy.additionalPreferences">
            </textarea-input-vertical>
          </div>

          <div class="form-group file-upload bottom-20">
            <label for="document">Attach Document</label>

            <div class="row tight-columns">
              <div class="col-sm-4">
                <file-selector
                  accept=".pdf, .doc, .docx, .rtf"
                  @change="addDocument"
                  class="file-selector"
                  :max-size="5">
                  <div class="secondary-btn-blue smaller file-selector-text">
                    {{ hasAttachedDocument ? 'Replace File' : 'Select File' }}
                  </div>
                </file-selector>
              </div>

              <div class="col-sm-8">
                <div class="size-text-13px top-10">
                  <span v-if="documentToDisplay">{{ documentToDisplay.name }}</span>
                  <span v-else class="missing-text">No file chosen</span>
                </div>
              </div>
            </div>
          </div>

          <div class="bottom-30">
            <input-checkbox
              id="hideDocuments"
              name="hideDocuments"
              v-model="requestCopy.hideDocuments">
              <span class="semibold-weight">Select if you <u>do not</u> want Priori to share this document with prospective attorneys.</span>
            </input-checkbox>
          </div>
        </div>
      </div>

      <div v-if="requestCopy.projectType || !requestCopy.fromInteractive()">
        <hr class="kenny-hr no-margin">

        <div class="row top-20">
          <div class="col-sm-6 col-lg-4">
            <loading-button name="submitRfp" lb-class="nv-button-blue a-button-size" @lb-click="handleSubmit(submitRequest)">{{ isEditing ? 'Save & Close' : 'Submit RFP' }}</loading-button>
          </div>
        </div>

        <div v-if="failed" class="error-text top-10">
          * One or more fields are invalid. Please fix errors as indicated above.
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { cloneDeep, find } from 'lodash';
import interactionModes from 'vue-app/shared/mixins/interaction-modes.js';
import uivScrollableDropdown from 'vue-app/shared/directives/uiv-scrollable-dropdown';
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import RfpDataService from 'vue-app/shared/services/rfp-data-service.js';

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import StyledDatePicker from 'vue-app/shared/components/styled-date-picker.vue';
import DropdownSelect from 'vue-app/shared/components/dropdown-select.vue';
import FileSelector from 'vue-app/shared/components/file-selector.vue';
import InputCheckbox from 'vue-app/shared/components/input-checkbox.vue';
import LoadingButton from 'vue-app/shared/components/loading-button.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import TextareaInputVertical from 'vue-app/shared/components/textarea-input-vertical.vue';
import TextInputVertical from 'vue-app/shared/components/text-input-vertical.vue';
import TypeaheadVertical from 'vue-app/shared/components/typeahead-vertical.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';

import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'RfpFullFormStatic',

  components: {
    DropdownSelect,
    FileSelector,
    InputCheckbox,
    LoadingButton,
    StyledDatePicker,
    SvgIcon,
    TextareaInputVertical,
    TextInputVertical,
    TypeaheadVertical,
    ValidationObserver,
    ValidationProvider,
    LoadingSection
  },

  directives: {
    uivScrollableDropdown: uivScrollableDropdown
  },

  mixins: [
    interactionModes
  ],

  props: {
    request: {
      type: Object,
      required: true
    },

    isEditing: {
      type: Boolean,
      default: false
    },

    onSubmit: {
      type: Function,
      required: true
    }
  },

  data() {
    const requestCopy = this.initializeRequestCopy();

    return {
      requestCopy: requestCopy,
      isAsapString: null,
      practiceArea: '',

      // misc
      renderDatePicker: false,

      // dropdowns
      elementsToNotCloseOn: [],
      additionalExperienceDropdownOpen: false,
      firmAccreditationsDropdownOpen: false,

      // service
      rfpDataService: new RfpDataService()
    };
  },

  computed: {
    ...mapState(useCurrentUserStore, { user: 'currentUser' }),

    firmsEnabled() {
      return this.user.rfpFirmsEnabled;
    },

    lists() {
      return this.rfpDataService.lists;
    },

    stateNames() {
      return this.lists.states.map(state => state.name);
    },

    requiredBarsHaveUnitedStatesWithState() {
      return this.requestCopy.requiredBars.some(
        bar => bar.country === 'United States' && bar.state !== null
      );
    },

    hasMoreThanOneRequiredBar() {
      return this.requestCopy.requiredBars.length > 1;
    },

    barCountriesList() {
      return this.lists.barCountries.map(c => c.country);
    },

    requiredBarCountries() {
      return this.requestCopy.requiredBars.map(b => b.country);
    },

    barCountryNames() {
      return this.barCountriesList.filter(country => {
        if (country === 'United States') {
          return this.requiredBarsHaveUnitedStatesWithState;
        }
        else {
          return !this.requiredBarCountries.includes(country);
        }
      });
    },

    barStatesList() {
      return this.lists.barStates.map(s => s.name);
    },

    requiredBarStates() {
      return this.requestCopy.requiredBars.map(b => b.state);
    },

    barStateNames() {
      return this.barStatesList.filter(state => !this.requiredBarStates.includes(state));
    },

    practiceAreas() {
      return this.lists.practiceAreas.map(practiceArea => {
        let name = practiceArea.practiceArea.name;

        if (practiceArea.name !== name) {
          name += ` > ${practiceArea.name}`;
        }

        return {
          label: name,
          name: practiceArea.name
        };
      });
    },

    budgetLowerRangeRules() {
      const rules = { positive: true };

      if (this.requestCopy.budgetRangeUpper) {
        rules['required'] = true;
      }

      return rules;
    },

    budgetFeeInitialLabel() {
      return this.lists.feeStructureOptions.find(option => option.slug === this.requestCopy.budgetFeeStructure)?.label;
    },

    budgetRangeUpperRules() {
      const rules = { positive: true };

      if (this.requestCopy.budgetRangeLower) {
        rules['minValue'] = parseInt(this.requestCopy.budgetRangeLower);
      }

      return rules;
    },

    firmAccreditations() {
      return this.lists.accreditationOptions.map(option => option.label);
    },

    selectedFirmAccreditations() {
      const selected = Object.keys(this.requestCopy.firmAccreditations);

      return this.lists.accreditationOptions.filter(
        option => selected.includes(option.name)
      ).map(option => option.label);
    },

    stateDisabled() {
      return this.requestCopy.country !== 'United States';
    },

    allBarsValid() {
      const invalid = [];

      this.requestCopy.requiredBars.forEach((bar) => {
        if (!bar.country) {
          invalid.push(bar);
        }
        else if (this.requiredBarsHaveUnitedStatesWithState && bar.country === 'United States' && !bar.state) {
          invalid.push(bar);
        }
      });

      return !invalid.length;
    },

    hasAttachedDocument() {
      return !!this.documentToDisplay;
    },

    documentToDisplay() {
      if (this.requestCopy.document) {
        return this.requestCopy.document;
      }
      else if (this.requestCopy.documents && this.requestCopy.documents[0]) {
        return this.requestCopy.documents[0];
      }
      else {
        return null;
      }
    }
  },

  created() {
    this.requestCopy.projectType = (this.request.projectType || {}).slug;

    if (this.request.fromInteractive()) {
      this.processRfpForForm();
      this.setIsAsapString();
    }
  },

  mounted() {
    this.setElementsToNotCloseOn();
  },

  methods: {
    initializeRequestCopy() {
      const request = cloneDeep(this.request);

      request.budgetRangeLower = request.budgetRangeLower === 0 ? null : request.budgetRangeLower;
      request.budgetRangeUpper = request.budgetRangeUpper === 0 ? null : request.budgetRangeUpper;
      request.document = null;
      request.experienceDiverse = request.experienceDiverse ? request.experienceDiverse : false;
      request.experienceVeteran = request.experienceVeteran ? request.experienceVeteran : false;
      request.flexibleBudget = request.flexibleBudget ? request.flexibleBudget : false;
      request.hideDocuments = request.hideDocuments ? request.hideDocuments : false;
      request.preferenceFirmDiverse = request.preferenceFirmDiverse ? request.preferenceFirmDiverse : false;
      request.preferenceFirmVeterans = request.preferenceFirmVeterans ? request.preferenceFirmVeterans : false;
      request.projectType = request.projectType ? request.projectType : null;

      return request;
    },

    minRangeRules(upperRangeAttribute) {
      const rules = { positive: true };

      if (this.requestCopy[upperRangeAttribute]) {
        rules['required'] = true;
      }

      return rules;
    },

    optionalMaxRangeRules(lowerRangeAttribute) {
      const rules = { positive: true };

      if (this.requestCopy[lowerRangeAttribute]) {
        rules['minValue'] = parseInt(this.requestCopy[lowerRangeAttribute]) + 1;
      }

      return rules;
    },

    resetCompanyState(country) {
      if (country === 'United States') { return; }
      this.requestCopy.state = null;
      this.$refs.companyLocationState.reset();
    },

    formattedDate(date) {
      return DateFilter.filter(date);
    },

    setStartDate(date) {
      this.requestCopy.startDate = new Date(date);
      this.renderDatePicker = false;
    },

    setElementsToNotCloseOn() {
      this.elementsToNotCloseOn = [];

      this.$nextTick(() => {
        if (this.$refs.firmAccreditationsDropdown) {
          this.elementsToNotCloseOn.push(this.$refs.firmAccreditationsDropdown.$refs.dropdown);
        }

        if (this.$refs.additionalExperienceDropdown) {
          this.elementsToNotCloseOn.push(this.$refs.additionalExperienceDropdown.$refs.dropdown);
        }
      });
    },

    minValueRule(field) {
      return {
        minValue: parseInt(this.requestCopy[field]) + 1
      };
    },

    processRfpForForm() {
      if (this.requestCopy.feeStructure === 'other') {
        this.requestCopy.budgetFeeStructure = 'other';
      }
      else if (this.requestCopy.feeInterval) {
        this.requestCopy.budgetFeeStructure = this.requestCopy.feeInterval;
      }

      if (this.requestCopy.startDate) {
        this.requestCopy.startDate = new Date(this.requestCopy.startDate);
      }
    },

    setIsAsapString() {
      if (this.requestCopy.startDateType === 'asap') {
        this.isAsapString = 'As soon as possible';
      }
      else if (['day', 'month'].includes(this.requestCopy.startDateType)) {
        this.isAsapString = 'Specific date';
      }
    },

    setIsAsap() {
      if (this.isAsapString === 'As soon as possible') {
        this.requestCopy.startDateType = 'asap';
      }
      else if (this.isAsapString === 'Specific date') {
        this.requestCopy.startDateType = 'day';
      }
      else {
        this.requestCopy.startDateType = null;
      }
    },

    toggleRenderDatePicker() {
      this.renderDatePicker = !this.renderDatePicker;
    },

    dateYearMonthFormat(year, month) {
      month = new Date(year, month).toLocaleString('default', { month: 'long' });
      return `${month.toUpperCase()} ${year}`;
    },

    labelForSlug(list, slug) {
      return (find(list, { slug: slug }) || {}).label;
    },

    onSelectType(projectType) {
      if (this.requestCopy.projectType === projectType) { return; }

      this.requestCopy.projectType = projectType;
      this.requestCopy.professionalType = 'Lawyer';
      this.setElementsToNotCloseOn();
    },

    statePlaceHolder(country) {
      return country === 'United States' ? 'State' : 'N/A';
    },

    addOfficeLocation() {
      this.requestCopy.lawFirmOfficeLocations.push({
        country: 'United States', state: null, city: null
      });
    },

    updateBars() {
      this.requestCopy.requiredBars.forEach((bar) => {
        if (bar.country && bar.country !== 'United States') { bar.state = null; }
        if (bar.state && !bar.country) { bar.state = null; }
      });
    },

    addBar() {
      if (!this.allBarsValid) { return; }

      const defaultCountry = this.requestCopy.requiredBars.length ? null : 'United States';

      this.requestCopy.requiredBars.push({ country: defaultCountry, state: null });

      if (this.hasMoreThanOneRequiredBar) {
        const lastBarAdded = this.requestCopy.requiredBars[this.requestCopy.requiredBars.length - 2];

        if (lastBarAdded.state) {
          find(this.lists.barStates, { name: lastBarAdded.state }).selected = true;
        }
        else if (lastBarAdded.country) {
          find(this.lists.barCountries, { country: lastBarAdded.country }).selected = true;
        }
      }

      this.updateBars();
    },

    removeBar(bar, index) {
      this.requestCopy.requiredBars.splice(index, 1);

      if (bar.state) {
        find(this.lists.barStates, { name: bar.state }).selected = false;
      }
      else if (bar.country) {
        find(this.lists.barCountries, { country: bar.country }).selected = false;
      }
    },

    removeOfficeLocation(index) {
      this.requestCopy.lawFirmOfficeLocations.splice(index, 1);
    },

    updateLogic(field, logic) {
      this.requestCopy[field] = logic;
    },

    addPracticeArea(practiceAreaName) {
      if (!practiceAreaName) { return; }

      let newPracticeAreaName = practiceAreaName;
      let capitalizedString = '';
      let practiceArea = this.lists.practiceAreas.find(p => p.name === practiceAreaName);

      if (practiceArea && newPracticeAreaName !== practiceArea.practiceArea.name) {
        newPracticeAreaName += ' (' + practiceArea.practiceArea.name + ')';
      }
      capitalizedString = newPracticeAreaName.replace(/(^|[\s-])\S/g, function (match) {
        return match.toUpperCase();
      });

      if (!this.requestCopy.practiceAreas.includes(capitalizedString)) {
        this.requestCopy.practiceAreas.push(capitalizedString);
      }
      this.practiceArea = '';
      this.$refs.requestedExperience.reset();
    },

    removePracticeArea(index) {
      this.requestCopy.practiceAreas.splice(index, 1);
    },

    addDocument(document) {
      this.requestCopy.document = document[0];
    },

    submitRequest() {
      if (this.requestCopy.startDate) {
        this.requestCopy.startDate = new Date(this.requestCopy.startDate);
      }

      this.requestCopy.nameForClientsReference = this.requestCopy.nameForLawyersReference;

      if (this.requestCopy.fromInteractive()) {
        this.rfpDataService.processRfpForSubmit(this.requestCopy, this.isEditing);
      }

      LoadingService.loading('submitRfp');
      this.onSubmit(this.requestCopy).then(() => { LoadingService.done('submitRfp'); });
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";

  .file-selector {
    width: 100%;
  }

  .secondary-btn-blue.file-selector-text {
    padding-top: 10px;
  }

  .styled-date-picker {
    position: absolute;
    z-index: 1000;
  }

  .date-picker-error {
    border-color: $k-red !important;
  }
</style>
