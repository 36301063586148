import axios from 'axios';
import BaseResource from 'resources/base-resource';
import SharedLists from 'vue-app/shared/services/shared-lists-service.js';
import { filter, find, get, includes, map, some, without } from 'lodash';

class CounselRequest extends BaseResource {
  static baseUrl = '/counsel_requests/:id.json';

  static reopen(id, data) {
    const url = `${this.baseUrl.replace(':id.json', id)}/reopen.json`;
    return axios.post(url, data);
  }

  static clientClose(id, data) {
    return axios.put(`/counsel_requests/${id}/client_close.json`, data);
  }

  static latest() {
    return axios.get('/counsel_requests/latest.json');
  }

  newStatus() {
    return this.status === 'new';
  }

  sentToClient() {
    return this.status === 'sent_to_client';
  }

  rejectedMax() {
    return this.status === 'rejected_all_suggestions' ||
      this.status === 'rejected_max_suggestions';
  }

  matterOpen() {
    return this.status === 'matter_opened';
  }

  adminClosed() {
    return this.status === 'admin_closed';
  }

  completed() {
    return this.matterOpen() || this.adminClosed();
  }

  fromInteractive() {
    return includes(this.requestPageUrl, 'public-app/rfp') || includes(this.requestPageUrl, 'client-app/requests/new');
  }

  lawyerContacted() {
    return some(this.possibleLawyers || [], (p) => {
      return p.status !== 'unsent' && p.status !== 'suggested';
    });
  }

  isPeopleType() {
    return get(this.projectType, 'slug', this.projectType) === 'overflow';
  }

  wantsFirm() {
    return this.professionalType === 'Law Firm';
  }

  adminDisplayStatus() {
    if (this.newStatus()) {
      return 'Request Made';
    }
    else if (this.adminClosed() && this.closeType === 'spam') {
      return 'Closed/Disqualified';
    }
    else if (this.adminClosed() && this.closeType === 'lost') {
      return 'Closed/Lost';
    }
    else if (this.adminClosed() && this.closeType === 'client_filled_another_RFP') {
      return 'Client Filled/Another RFP';
    }
    else if (this.adminClosed() && this.closeType === 'client_filled_internal') {
      return 'Client Filled/Internal';
    }
    else if (this.adminClosed() && this.closeType === 'client_filled_outside_source') {
      return 'Client Filled/Outside Source';
    }
    else if (this.adminClosed() && this.closeType === 'decided_not_to_hire') {
      return 'Decided not to hire';
    }
    else if (this.adminClosed() && this.closeType === 'lost_budget') {
      return 'Lost Budget';
    }
    else if (this.adminClosed() && this.closeType === 'put_on_hold') {
      return 'Project put on hold';
    }
    else if (this.adminClosed() && this.closeType === 'closed_duplicate') {
      return 'Closed (duplicate)';
    }
    else if (this.adminClosed() && this.closeType === 'internal_test_request') {
      return 'Internal Test Request';
    }
    else if (this.adminClosed() && this.closeType === 'closed_unknown') {
      return 'Closed (unknown reason)';
    }
    else if (this.adminClosed()) {
      return 'Closed/Lost';
    }
    else if (this.matterOpen()) {
      return 'Closed/Won';
    }
    else if (this.lawyerContacted()) {
      return 'Lawyer Contacted';
    }
    else {
      return 'Request Fulfilled';
    }
  }

  stateBarSelected() {
    return find(this.requiredBars, (bar) => { return bar.country === 'United States' && bar.state; });
  }

  experienceRequired() {
    const experience = this.selectedExperiences();
    return experience.length ? experience.join(', ') : 'N/A';
  }

  budget() {
    if (!this.feeStructure) {
      return 'N/A';
    }
    else {
      let range = [];
      let rate = '';

      if (this.budgetRangeLower) { range.push('$' + this.budgetRangeLower); }
      if (this.budgetRangeUpper) { range.push('$' + this.budgetRangeUpper); }

      if (this.feeStructure !== 'other' && this.feeInterval) {
        rate = this.feeInterval.replace('_', ' ');
      }
      else {
        rate = this.feeStructureDescription;
      }

      return range.join(' - ') + ' ' + rate;
    }
  }

  insuranceNeeded() {
    if (this.requireMalpracticeInsurance === true) {
      return 'Yes';
    }
    else if (this.requireMalpracticeInsurance === false) {
      return 'No';
    }
    else {
      return 'N/A';
    }
  }

  selectedExperiences() {
    const cr = this;
    return map((filter(SharedLists.rfpExperienceOptions, (option) => { return cr[option.name]; })), 'label');
  }

  deselectExperience(experienceLabel) {
    const experience = find(SharedLists.rfpExperienceOptions, { 'label': experienceLabel });
    this[experience.name] = false;
  }

  selectedAccreditations() {
    const cr = this;
    return map((filter(SharedLists.rfpAccreditationOptions, (option) => { return cr.firmAccreditations[option.name]; })), 'label');
  }

  deselectAccreditation(accreditationLabel) {
    const accreditation = find(SharedLists.rfpAccreditationOptions(), { 'label': accreditationLabel });
    this.firmAccreditations[accreditation.name] = false;
  }

  addOfficeLocation() {
    this.lawFirmOfficeLocations.push({ country: 'United States', state: null, city: null });
  }

  removeOfficeLocation(location) {
    this.lawFirmOfficeLocations = without(this.lawFirmOfficeLocations, location);
  }

  hasInvalidOfficeLocations() {
    return this.lawFirmOfficeLocations.some((loc) => { return !loc.country; });
  }

  displayStatus() {
    switch (this.status) {
      case 'new':
        return 'RFP Submitted';

      case 'sent_to_client':
        return 'RFP Pending';

      case 'matter_opened':
      case 'admin_closed':
        return 'RFP Closed';

      default:
        return this.status;
    }
  }
}

export default CounselRequest;
