<template>
  <div class="shadowed-box">
    <div class="box-content symmetrical">
      <div class="bottom-40">
        <div class="size-text-21px bottom-10">
          {{ matterTitle }}
        </div>

        <div class="bold-weight bottom-2">
          Reviewed by
        </div>

        <div class="inline-flex">
          <div class="initials-circle">
            {{ reviewerInitials }}
          </div>

          <div class="left-5">
            {{ review.reviewer.fullName }}
            <span v-if="reviewerRole">({{ reviewerRole }})</span>
            on {{ updatedAt }}
          </div>
        </div>
      </div>

      <div class="bottom-40" v-if="hasAnyWorkPerformedResponses">
        <div class="size-text-17px">
          Work Performed
        </div>

        <div class="top-5">
          <hr class="no-margin">
        </div>

        <div class="top-20" v-if="hasScoutMatter">
          <div class="bold-weight">
            Matter Name
          </div>

          <div>
            <a :href="`scout-company/matters/${review.scoutMatterId}`">{{ review.matterName }}</a>
          </div>

          <div class="pill-gray-text text-italic" v-if="review.matterCode">
            #{{ review.matterCode }}
          </div>
        </div>

        <div class="top-20" v-if="hasLawFirmLocation">
          <div class="bold-weight">
            Office Location
          </div>

          <div>
            {{ officeLocation }}
          </div>
        </div>

        <div class="top-20" v-if="hasPracticeAreas">
          <div class="bold-weight bottom-5">
            Practice Areas
          </div>

          <div class="review-practice-areas">
            <div class="tag-list">
              <div v-for="(practiceAreaName, index) in review.practiceAreaNames" :key="`${practiceAreaName}-${index}`" class="tag-list-item lighter-gray compact">
                {{ practiceAreaName }}
              </div>
            </div>
          </div>
        </div>

        <div class="top-20" v-if="showComplexity || showRisk">
          <div class="row tight-columns">
            <div class="col-sm-6 col-md-5 col-lg-4" id="review-complexity" v-if="showComplexity">
              <div class="bold-weight tight-lines">
                {{ reviewTemplate.complexityPromptText }}
              </div>

              <div class="top-10">
                <level-widget :label="complexityLabel" :score="review.complexity"></level-widget>
              </div>
            </div>

            <div class="col-sm-6 col-md-5 col-lg-4 top-20-xs" v-if="showRisk">
              <div class="bold-weight tight-lines">
                {{ reviewTemplate.riskPromptText }}
              </div>

              <div class="top-10">
                <level-widget :label="riskLabel" :score="review.risk"></level-widget>
              </div>
            </div>
          </div>
        </div>

        <div class="top-20" v-if="hasBooleanResponses">
          <div class="list-spacing-20" v-for="response in booleanResponses" :key="response.key">
            <div class="bold-weight bottom-5">
              {{ response.text }}
            </div>

            <div class="level-widget">
              {{ response.value ? 'Yes' : 'No' }}
            </div>
          </div>
        </div>

        <div class="top-20" v-if="hasSelectQuestionResponses">
          <div class="list-spacing-20" v-for="response in selectQuestionResponses" :key="response.key">
            <div class="bold-weight bottom-5">
              {{ response.text }}
            </div>

            <div>
              {{ response.value }}
            </div>
          </div>
        </div>
      </div>

      <div class="size-text-17px">
        <div>
          Recommendation Score
        </div>

        <div class="top-5 bottom-15">
          <hr class="no-margin">
        </div>

        <div>
          <div v-if="review.recommendation">
            {{ review.recommendation }}<span class="dark-gray-text"> / 10</span>
          </div>

          <div v-else>
            N/A
          </div>
        </div>
      </div>

      <div class="top-40" v-if="reviewScores.length">
        <div class="size-text-17px">
          {{ isLawyerReview ? 'Attorney' : 'Law Firm' }} Performance Review
        </div>

        <div class="top-5 bottom-20">
          <hr class="no-margin">
        </div>

        <performance-score-with-left-label
          :scores="reviewScores"
          :legend="responseLegend">
        </performance-score-with-left-label>
      </div>

      <div class="top-40" v-if="review.experience">
        <div class="size-text-17px">
          Overall Experience
        </div>

        <div class="top-5 bottom-15">
          <hr class="no-margin">
        </div>

        <div>
          <span class="simple-text">{{ review.experience }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LevelWidget from 'vue-app/shared/components/level-widget.vue';
import PerformanceScoreWithLeftLabel from 'vue-app/scout/shared/performance-score-with-left-label.vue';
import DateFilter from 'vue-app/shared/lib/date-filter.js';
import { map, compact, isNull, uniqueId } from 'lodash';

export default {
  name: 'ReviewDetails',

  components: {
    LevelWidget,
    PerformanceScoreWithLeftLabel
  },

  props: {
    review: {
      type: Object,
      required: true
    },

    reviewTemplate: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      complexityLabels: this.reviewTemplate.complexityLabels(),
      riskLabels: this.reviewTemplate.riskLabels()
    };
  },

  computed: {
    isLawyerReview() {
      return this.review.resourceType === 'Scout::LawyerBridge';
    },

    isLawFirmReview() {
      return this.review.resourceType === 'Scout::LawFirmBridge';
    },

    matterTitle() {
      return this.review.matterName ? `Matter Review: ${this.review.matterName}` : 'General Review';
    },

    updatedAt() {
      return DateFilter.filter(this.review.updatedAt);
    },

    reviewerInitials() {
      return (this.review.reviewer.firstName[0] + this.review.reviewer.lastName[0]).toUpperCase();
    },

    reviewerRole() {
      return this.review.reviewer.role;
    },

    hasPracticeAreas() {
      return this.review.practiceAreaNames.length > 0;
    },

    showComplexity() {
      return this.reviewTemplate.complexityShown && this.review.complexity;
    },

    complexityLabel() {
      return this.complexityLabels[this.review.complexity - 1] || 'N/A';
    },

    showRisk() {
      return this.reviewTemplate.riskShown && this.review.risk;
    },

    riskLabel() {
      return this.riskLabels[this.review.risk - 1] || 'N/A';
    },

    reviewScores() {
      const titles = map(this.reviewTemplate.reviewQuestions(), 'title');

      const responses = compact(map(titles, (title) => {
        return this.review.responses.find(score => score.field === title);
      }));

      return responses.filter(resp => resp.value > 0);
    },

    responseLegend() {
      return this.reviewTemplate.constructor.responseAnswerLegend();
    },

    booleanResponses() {
      const questions = this.reviewTemplate.booleanQuestions();
      const responses = this.review.booleanResponses.filter(response => !isNull(response.value));

      return responses.map(response => (
        {
          text: questions.find(q => q.title === response.field).text,
          value: response.value,
          key: uniqueId()
        }
      ));
    },

    selectQuestionResponses() {
      const questions = this.reviewTemplate.selectQuestions();
      const responses = this.review.selectQuestionResponses.filter(response => !isNull(response.value));

      return responses.map(response => (
        {
          text: questions.find(q => q.title === response.field).text,
          value: response.value,
          key: uniqueId()
        }
      ));
    },

    officeLocation() {
      const location = this.review.lawFirmLocation;

      if (location.state) {
        return `${location.city}, ${location.state}`;
      }
      else {
        return `${location.city}, ${location.country}`;
      }
    },

    hasScoutMatter() {
      return !!this.review.scoutMatterId;
    },

    hasLawFirmLocation() {
      return this.isLawFirmReview && this.review.lawFirmLocation;
    },

    hasBooleanResponses() {
      return this.booleanResponses.length > 0;
    },

    hasSelectQuestionResponses() {
      return this.selectQuestionResponses.length > 0;
    },

    hasAnyWorkPerformedResponses() {
      return this.hasScoutMatter ||
        this.hasLawFirmLocation ||
        this.hasPracticeAreas ||
        this.showComplexity ||
        this.showRisk ||
        this.hasBooleanResponses ||
        this.hasSelectQuestionResponses;
    }
  }
};
</script>
