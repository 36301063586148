<template>
  <div class="shadowed-box">
    <div class="box-content symmetrical">
      <div class="text-center">
        <div class="clickable" @click="viewScoutFirm(firm.id)">
          <div class="bottom-10">
            <svg-icon name="law-firm" class="firm-icon"></svg-icon>
          </div>

          <div class="size-text-18px target">
            {{ firm.name }}
          </div>
        </div>

        <div v-if="firm.firmSize">
          {{ firm.firmSize }}
        </div>

        <div v-if="currentProduct.isScout" class="top-20">
          {{ matterCountText }} with {{ workspaceName }}
        </div>

        <div class="top-20" v-if="firm.reviewsSummary.score">
          <div class="bottom-5">
            Average Law Firm Score
          </div>

          <div class="vertical-center center-justified">
            <rating-circle class="right-5" :rating="rating"></rating-circle>
            <span class="semibold-weight right-5">{{ scoreText }}</span>
            <span class="right-5 bold-weight">&#8231;</span>
            <span class="pill-gray-text">{{ reviewsCountText }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RatingCircle from 'vue-app/scout/shared/rating-circle.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import useCurrentUserStore from 'vue-app/stores/current-user.js';
import { mapState } from 'pinia';

export default {
  name: 'FirmCard',

  components: {
    RatingCircle,
    SvgIcon
  },

  props: {
    firm: {
      type:     Object,
      required: true
    }
  },

  computed: {
    ...mapState(useCurrentUserStore, ['currentUser', 'currentProduct']),

    matterCountText() {
      return this.$pluralize('matter', this.firm.matterCount || 0, true);
    },

    workspaceName() {
      return this.currentUser.workspace?.name;
    },

    reviewsCountText: function () {
      return this.$pluralize('review', this.firm.reviewsSummary.count, true);
    },

    rating() {
      return { percent: (this.firm.reviewsSummary.score / 10 * 100) };
    },

    scoreText() {
      return this.firm.reviewsSummary.score.toFixed(1);
    }
  }
};
</script>

<style lang="scss" scoped>
  .firm-icon {
    width: 60px;
    height: 60px;
  }
</style>
