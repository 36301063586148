<template>
  <div :class="['list-item matter-row no-hover', { 'expanded': isExpanded }]">
    <div class="row tight-columns vertical-center-not-xs">
      <div class="col-sm-4">
        <firm-name-plate :firm="lawFirm" icon-size="small" :italic="lawFirm.isBillingFirm()">
          <template #firm-name-element>
            <a :href="`/scout-company/law-firms/${lawFirm.id}`" :class="['target', 'text-link', { 'text-italic': lawFirm.isBillingFirm() }]">{{ lawFirm.name }}</a>
          </template>

          <div>{{ lawFirm.firmSize }}</div>
        </firm-name-plate>
      </div>

      <div class="col-sm-5 top-10-xs">
        <scout-tags-list-with-modal
          :scout-tags="lawFirm.scoutTags"
          :firm-name="lawFirm.name">
        </scout-tags-list-with-modal>
      </div>

      <div class="col-sm-3">
        <div class="row tight-columns vertical-center-not-xs">
          <div class="col-sm-6 text-nowrap top-10-xs">
            <a :href="newReviewUrl" class="bold-weight blue-link" target="_self">Leave a review</a>

            <div>{{ reviewsCountText }}</div>
          </div>

          <div class="col-sm-6 text-right-not-xs">
            <button type="button" class="nv-button-square-white" @click="toggleReviews"><svg-icon name="caret-right" :class="['base-icon disclosure-icon', { 'expanded': isExpanded }]"></svg-icon></button>
          </div>
        </div>
      </div>
    </div>

    <div class="top-20" v-if="isExpanded">
      <div class="top-20" v-if="hasReviews">
        <scout-review-summary
          v-for="review in lawFirm.reviews"
          :key="`review-${review.id}`"
          :review="review"
          :review-template="reviewTemplate"
          class="list-spacing-10">
        </scout-review-summary>
      </div>
    </div>
  </div>
</template>

<script>
import FirmNamePlate from 'vue-app/scout/shared/firm-name-plate.vue';
import ScoutReviewSummary from 'vue-app/scout/client/lawyers/reviews/scout-review-summary.vue';
import ScoutTagsListWithModal from 'vue-app/scout/shared/scout-tags/scout-tags-list-with-modal.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'MatterLawFirm',

  components: {
    FirmNamePlate,
    ScoutReviewSummary,
    ScoutTagsListWithModal,
    SvgIcon
  },

  props: {
    lawFirm: {
      type:     Object,
      required: true
    },

    matter: {
      type:     Object,
      default:  () => ({})
    },

    reviewTemplate: {
      type:     Object,
      required: true
    }
  },

  data() {
    return {
      isExpanded: false
    };
  },

  computed: {

    hasReviews() {
      return this.lawFirm.reviews.length > 0;
    },

    reviewsCountText() {
      return this.hasReviews ? this.$pluralize('review', this.lawFirm.reviews.length, true) : 'No reviews';
    },

    newReviewUrl() {
      if (this.matter.id) {
        return `/scout-company/law-firms/${this.lawFirm.id}/reviews/new?matterId=${this.matter.id}`;
      }
      else {
        return `/scout-company/law-firms/${this.lawFirm.id}/reviews/new`;
      }
    }
  },

  methods: {
    toggleReviews() {
      this.isExpanded = !this.isExpanded;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .list-item.matter-row:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .resource-list.static-list .list-item {
    padding: 20px 30px;
    border-bottom-color: $k-gray;

    &.expanded {
      padding-left: 25px;
    }
  }

  .full-width {
    width: 100%
  }
</style>
