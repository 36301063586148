<template>
  <div class="shadowed-box">
    <div class="box-header text-uppercase">
      Client Requests
    </div>

    <div class="box-content normal-weight">
      <loading-section name="clientRequests">
        <div v-if="possibleLawyers.length">
          <div v-for="(possibleLawyer, index) in displayPossibleLawyers" :key="possibleLawyer.id">
            <client-request
              :possible-lawyer="possibleLawyer"
              :on-message-client="messageClient"
              :on-send-proposal="sendProposal"
              :on-schedule-call="scheduleCall"
              :on-reject-request="rejectRequest">
            </client-request>

            <hr class="kenny-hr" v-if="index !== displayPossibleLawyers.length - 1">
          </div>

          <div v-if="possibleLawyers.length > 3">
            <hr class="kenny-hr">

            <div class="view-all-container">
              <a href="/lawyer-app/projects/filter/pending" class="purple-text">View All Requests</a>
            </div>
          </div>
        </div>

        <div v-else class="top-20 bottom-20 gray-text tight-lines text-center">
          No pending requests.
        </div>
      </loading-section>
    </div>
  </div>
</template>

<script>
import ClientRequest from 'vue-app/marketplace/lawyer/dashboard/client-request.vue';
import LoadingSection from 'vue-app/shared/components/loading-section.vue';
import { orderBy, slice } from 'lodash';

export default {
  name: 'ClientRequests',

  components: {
    ClientRequest,
    LoadingSection
  },

  props: {
    possibleLawyers: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    displayPossibleLawyers() {
      return slice(orderBy(this.possibleLawyers, ['updatedAt'], ['desc']), 0, 3);
    }
  },

  methods: {
    messageClient(client, counselRequest) {
      this.$emit('message-client', client, counselRequest);
    },

    sendProposal(possibleLawyer) {
      this.$emit('send-proposal', possibleLawyer);
    },

    scheduleCall(possibleLawyer) {
      this.$emit('schedule-call', possibleLawyer);
    },

    rejectRequest(possibleLawyer) {
      this.$emit('reject-request', possibleLawyer);
    }
  }
};
</script>
