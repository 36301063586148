<template>
  <div>
    <div class="vertical-center-not-xs bottom-40">
      <div class="relative-container inline-block right-10">
        <svg-icon name="law-firm" class="firm-icon medium-large"></svg-icon>
      </div>

      <div class="top-10-xs">
        <div class="size-text-17px bold-weight text-ellipsis">
          {{ firmName }}
        </div>

        <div class="size-text-14px semibold-weight">
          {{ rfqName }}
        </div>
      </div>
    </div>

    <div class="size-text-17px bold-weight">
      RFP Details
    </div>

    <div class="top-5 bottom-10">
      <hr class="no-margin">
    </div>

    <div class="missing-text bottom-20">
      Below are the project details for your RFP
    </div>

    <div class="row bottom-30">
      <div class="col-sm-7">
        <div>
          <div class="bold-weight bottom-5">
            Description of Work
          </div>

          <div>
            <span class="simple-text">{{ rfq.descriptionOfWork }}</span>
          </div>
        </div>

        <div class="top-20" v-if="attributeIsProvided(rfq.counterpartyDetails)">
          <div class="bold-weight bottom-5">
            Any relevant opposing party/counterparty details, including counsel
          </div>

          <div>
            <span class="simple-text">{{ rfq.counterpartyDetails }}</span>
          </div>
        </div>

        <div class="top-20" v-if="attributeIsProvided(rfq.estimatedTimeline)">
          <div class="bold-weight bottom-5">
            Estimated Timeline
          </div>

          <div>
            <span class="simple-text">{{ rfq.estimatedTimeline }}</span>
          </div>
        </div>

        <div class="top-20" v-if="attributeIsProvided(rfq.preferredRateStructure)">
          <div class="bold-weight bottom-5">
            Preferred Rate Structure
          </div>

          <div>
            <span class="simple-text">{{ rfq.preferredRateStructure }}</span>
          </div>
        </div>

        <div class="top-20" v-if="attributeIsProvided(rfq.additionalInformation)">
          <div class="bold-weight bottom-5">
            Additional Information
          </div>

          <div>
            <span class="simple-text">{{ rfq.additionalInformation }}</span>
          </div>
        </div>

        <div class="top-20" v-if="hasSupportingDocuments">
          <div class="bold-weight bottom-5">
            Supporting Documents ({{ rfq.supportingDocuments.length }})
          </div>

          <ul class="compact">
            <li class="list-spacing-2" v-for="doc in rfq.supportingDocuments"><a :href="doc.url" class="bold-weight" target="_blank" rel="noopener">{{ doc.name }}</a></li>
          </ul>
        </div>

        <div class="top-30" v-if="hasSuggestedLawyers">
          <div class="size-text-15px bold-weight heading-underline bottom-10">
            Suggested Lawyers
          </div>

          <div class="bottom-15">
            {{ rfq.workspaceName }} has suggested these lawyers for this project.
          </div>

          <div v-for="lawyer in orderedSuggestedLawyers" :key="lawyer.id">
            <div class="top-10 bottom-10">
              <hr class="no-margin">
            </div>

            <rfq-suggested-lawyer v-if="lawyer.scoutLawyerBridge !== null" :lawyer="lawyer.scoutLawyerBridge"></rfq-suggested-lawyer>
            <rfq-suggested-lawyer v-else :lawyer="lawyer.lawyer"></rfq-suggested-lawyer>
          </div>

          <div class="top-10 bottom-10">
            <hr class="no-margin">
          </div>

          <div class="missing-text">
            Note: Firms responses may include alternative lawyers
          </div>
        </div>
      </div>

      <div class="col-sm-5 col-md-4 col-md-offset-1 top-30-xs">
        <div class="gray-panel">
          <div class="row tight-columns">
            <div class="col-xs-2">
              <svg-icon name="clients" class="base-icon"></svg-icon>
            </div>

            <div class="col-xs-10">
              <div class="bold-weight bottom-2">
                RFP Created By
              </div>

              <div>
                {{ rfq.creatorFullName }}
              </div>
            </div>
          </div>

          <div v-if="hasPracticeGroups" class="row tight-columns top-30">
            <div class="col-xs-2">
              <svg-icon name="court" class="base-icon">Practice group</svg-icon>
            </div>

            <div class="col-xs-10">
              <div class="bold-weight bottom-2">
                Practice Group
              </div>

              <div>
                {{ practiceGroupsText }}
              </div>
            </div>
          </div>

          <div class="row tight-columns top-30">
            <div class="col-xs-2">
              <svg-icon name="calendar" class="base-icon"></svg-icon>
            </div>

            <div class="col-xs-10">
              <div class="bold-weight bottom-2">
                Responses Due By
              </div>

              <div>
                {{ dueDate || '—' }}
              </div>
            </div>
          </div>

          <div class="row tight-columns top-30" v-if="budgetProvided">
            <div class="col-xs-2">
              <svg-icon name="dollar" class="base-icon"></svg-icon>
            </div>

            <div class="col-xs-10">
              <div class="bold-weight bottom-2">
                Budget
              </div>

              <div>
                {{ budgetText }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="lawFirmQuote.firmName !== 'Priori’s Marketplace'" class="questions-wrapper bottom-30">
      <rfq-recipients-list
        v-bind="recipientsListProps()">
      </rfq-recipients-list>
    </div>

    <div class="size-text-17px bold-weight">
      Law Firm Questions
    </div>

    <div class="top-5 bottom-10">
      <hr class="no-margin">
    </div>

    <div class="missing-text bottom-20">
      Below are the questions you provided to RFP recipients
    </div>

    <div class="questions-wrapper">
      <div v-for="group in orderedQuestionGroups" :key="group.id" class="list-spacing-30">
        <div class="size-text-15px bold-weight heading-underline bottom-20">
          {{ group.name }}
        </div>

        <div v-for="question in orderedQuestionsFor(group)" :key="question.id" class="list-spacing-20">
          <div class="bold-weight bottom-5">
            {{ question.questionText }}<span v-if="question.isRequired" class="red-text"> *</span>
          </div>

          <div class="missing-text">
            Pending firm response
          </div>
        </div>
      </div>

      <div class="missing-text size-text-14px" v-if="!hasIncludedQuestions">
        No questions added
      </div>
    </div>
  </div>
</template>

<script>
import RfqSuggestedLawyer from 'vue-app/scout/client/rfqs/suggested-lawyer.vue';
import RfqRecipientsList from 'vue-app/scout/client/rfqs/recipients-list.vue';
import CurrencyFormatter from 'src/vue-app/shared/misc/currency-formatter.js';
import RFQLawFirmQuote from 'src/resources/scout/rfq-law-firm-quote.js';
import DateFilter from 'vue-app/shared/lib/date-filter';
import { orderBy } from 'lodash';

export default {
  name: 'RfqPreview',

  components: {
    RfqSuggestedLawyer,
    RfqRecipientsList
  },

  props: {
    rfq: {
      type: Object,
      required: true
    },

    lawFirmQuote: {
      type: Object,
      required: true
    }
  },

  computed: {
    budgetProvided() {
      const { budgetMin, budgetMax } = this.rfq;

      if (!budgetMin && !budgetMax) {
        return false;
      }

      return true;
    },

    budgetText() {
      const { budgetMin, budgetMax } = this.rfq;

      if (!budgetMin && !budgetMax) {
        return '—';
      }

      if (budgetMin && !budgetMax) {
        return this.formatBudget(budgetMin);
      }

      if (!budgetMin && budgetMax) {
        return this.formatBudget(budgetMax);
      }

      return `${this.formatBudget(budgetMin)} - ${this.formatBudget(budgetMax)}`;
    },

    firmName() {
      return this.lawFirmQuote.lawFirmBridge === undefined ? this.lawFirmQuote.firmName : this.lawFirmQuote.lawFirmBridge.name;
    },

    hasSupportingDocuments() {
      return this.rfq.supportingDocuments && this.rfq.supportingDocuments.length > 0;
    },

    hasSuggestedLawyers() {
      return this.lawFirmQuote.suggestedLawyers && this.lawFirmQuote.suggestedLawyers.length > 0;
    },

    orderedSuggestedLawyers() {
      return orderBy(this.lawFirmQuote.suggestedLawyers, [
        'scoutLawyerBridge.lastName', 'scoutLawyerBridge.firstName', 'scoutLawyerBridge.fullName',
        'lawyer.lastName', 'lawyer.firstName', 'lawyer.fullName',
        'lawyerName'
      ]);
    },

    groupsWithQuestions() {
      return this.rfq.questionGroups.filter(group => group.questions && group.questions.some(question => question.isIncluded && !question.prerequisiteQuestionId));
    },

    hasIncludedQuestions() {
      return this.groupsWithQuestions.length > 0;
    },

    orderedQuestionGroups() {
      return orderBy(this.groupsWithQuestions, 'position');
    },

    dueDate() {
      return DateFilter.filterDate(this.rfq.dueDate);
    },

    hasPracticeGroups() {
      return this.rfq.practiceGroups.length > 0 || this.rfq.assignedClientPracticeGroup;
    },

    practiceGroupsText() {
      if (this.rfq.assignedClientPracticeGroup) {
        return this.rfq.assignedClientPracticeGroup;
      }

      return this.rfq.practiceGroups.map(pg => pg.name).join(', ');
    },

    rfqName() {
      return this.rfq.name || `Reference #${this.rfq.id}`;
    }
  },

  methods: {
    recipientsListProps() {
      const props = {
        firmName: this.lawFirmQuote.firmName || this.lawFirmQuote.lawFirm.name,
        provisionedLawyers: this.lawFirmQuote.lawyersToReceiveUpdates,
        additionalRecipients: this.lawFirmQuote.additionalRecipients
      };

      if (this.rfq.status === 'draft') {
        props.onRemoveRecipient = (additionalRecipientId) => this.removeAdditionalRecipient(additionalRecipientId);
      }

      return props;
    },

    removeAdditionalRecipient(additionalRecipientId) {
      RFQLawFirmQuote.deleteAdditionalRecipient(this.lawFirmQuote.id, additionalRecipientId).then((response) => {
        const quote = this.rfq.lawFirmQuotes.find((quote) => quote.id === this.lawFirmQuote.id);
        quote.additionalRecipients = response.data.additionalRecipients;
      });
    },

    orderedQuestionsFor(group) {
      return orderBy(group.questions.filter(q => q.isIncluded && !q.prerequisiteQuestionId), 'position');
    },

    attributeIsProvided(attr) {
      return attr !== undefined && attr !== null && attr !== '';
    },

    formatBudget(budget) {
      return CurrencyFormatter.onlyDollars().format(budget);
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/scout/variables";

  .questions-wrapper {
    padding: 20px;
    background-color: $k-lighter-gray;
    border-radius: $border-radius-large;

    @media (min-width: $screen-sm-min) {
      padding: 30px;
    }
  }
</style>
