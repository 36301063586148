<template>
  <div class="shadowed-box quick-actions-container">
    <div class="box-header text-uppercase">
      Quick Actions
    </div>

    <div class="quick-actions">
      <div class="action multiline" @click="updateAvailability">
        <div>
          <svg-icon name="circle" :class="`base-icon circle-icon ${currentAvailability.circleClass}`"></svg-icon>
        </div>

        <div>
          <div>
            Manage Availability
          </div>

          <div class="size-text-11px text-italic normal-weight">
            {{ currentAvailability.status }}
          </div>
        </div>
      </div>

      <div class="action text-ellipsis" @click="navigateToPath(newProjectPath)">
        <svg-icon name="new-project" class="base-icon"></svg-icon>Create New Project
      </div>

      <div class="action text-ellipsis" @click="navigateToPath(newProjectPath, 'invitingClient=true')">
        <svg-icon name="invite" class="base-icon"></svg-icon>Invite New Client
      </div>

      <div class="action text-ellipsis" @click="navigateToPath(newDocumentPath)">
        <svg-icon name="document" class="base-icon"></svg-icon>Share Document
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';
import DateFilter from 'vue-app/shared/lib/date-filter.js';

export default {
  name: 'LawyerQuickActions',

  components: {
    SvgIcon
  },

  props: {
    availabilityService: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },

  data() {
    return {
      newProjectPath: '/lawyer-app/projects/new',
      newDocumentPath: '/lawyer-app/documents/share',
      dateFormat: {
        month: '2-digit',
        day: '2-digit',
        year: '2-digit'
      }
    };
  },

  computed: {
    currentUnavailability() {
      return this.availabilityService.currentUnavailability;
    },

    indefiniteUnavailability() {
      return this.availabilityService.indefiniteUnavailability;
    },

    currentUnavailabilityEndDate() {
      return DateFilter.filter(this.currentUnavailability.endDate, this.dateFormat);
    },

    currentAvailability() {
      if (this.currentUnavailability) {
        return { status: `Out-of-Office Until ${this.currentUnavailabilityEndDate}`, circleClass: 'yellow' };
      }

      if (this.indefiniteUnavailability) {
        return { status: 'Indefinitely Unavailable', circleClass: 'red' };
      }

      return { status: 'Currently Available', circleClass: 'green' };
    }
  },

  methods: {
    updateAvailability() {
      this.availabilityService.openModal();
    },

    navigateToPath(path, params) {
      this.$emit('navigate-to-path', path, params);
    }
  }
};
</script>
