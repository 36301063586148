<template>
  <div>
    <div class="row">
      <div class="col-sm-8">
        <div class="dash-banner">
          <div class="row vertical-center">
            <div class="col-sm-7">
              <div class="bottom-15">
                <div class="logo-container">
                  <div class="vertical-center">
                    <svg-icon name="priori" class="base-icon larger"></svg-icon>
                  </div>
                </div>
              </div>

              <div class="size-text-18px bold-weight bottom-15">
                Welcome to Scout!
              </div>

              <div>
                Scout is a platform for you and your team at {{ currentClient.businessName }} to access detailed information about lawyers in your network.
              </div>
            </div>

            <div class="col-sm-5">
              <div class="hidden-xs dash-illustration"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-sm-4 top-30-xs">
        <div class="shadowed-box">
          <div class="box-header text-uppercase">
            Quick Actions
          </div>

          <div class="box-content symmetrical">
            <a href="/scout-company/search" class="secondary-btn-blue bottom-15">Search Lawyers</a>

            <a href="/scout-company/search?firms" class="secondary-btn-blue bottom-15">Search Law Firms</a>

            <suggest-a-firm></suggest-a-firm>
          </div>
        </div>
      </div>
    </div>

    <div class="shadowed-box top-40">
      <div class="box-content">
        <div class="row tight-columns vertical-center-not-xs">
          <div class="col-sm-2 col-md-1">
            <svg-icon name="feedback" class="feedback-icon"></svg-icon>
          </div>

          <div class="col-sm-7 col-md-8">
            <div class="size-text-20px bottom-5">
              Send us feedback
            </div>

            <div>
              Let us know what you like about Scout, what needs improvement, and any bugs you encounter.
            </div>
          </div>

          <div class="col-sm-3 top-20-xs">
            <scout-feedback></scout-feedback>
          </div>
        </div>
      </div>
    </div>

    <div v-if="hasRfqEnabled" class="top-40 row">
      <div class="col-sm-8">
        <rfq-notifications :notifications="notifications"></rfq-notifications>
      </div>
    </div>
  </div>
</template>

<script>
import SuggestAFirm from 'vue-app/scout/client/dashboard/suggest-a-firm.vue';
import ScoutFeedback from 'vue-app/scout/client/dashboard/scout-feedback.vue';
import RfqNotifications from 'vue-app/scout/client/dashboard/rfq-notifications.vue';
import SvgIcon from 'vue-app/shared/components/svg-icon.vue';

export default {
  name: 'ScoutClientDashboard',

  components: {
    SuggestAFirm,
    ScoutFeedback,
    RfqNotifications,
    SvgIcon
  },

  props: {
    currentClient: {
      type: Object,
      required: true
    },

    notifications: {
      type: Array,
      required: true
    }
  },

  computed: {
    hasRfqEnabled() {
      return this.currentClient.hasRfqEnabled;
    }
  }
};

</script>
