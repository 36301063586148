<template>
  <div>
    <search-input
      v-if="showFilter('matterNumber')"
      class="col-sm-4 col-lg-3 no-padding right-20 bottom-10"
      :placeholder="'Search by Matter Number'"
      :on-search="searchMatterByNumber"
      :on-reset="resetFilters">
    </search-input>

    <filter-button
      v-if="showFilter('firm')"
      label="Firm Name"
      class="right-5 bottom-10"
      :active="isActive('billing_firm_ids')">
      <template #default="{ isVisible }">
        <filter-multiselect
          :is-visible="isVisible"
          data-testid="firm-filter"
          :on-apply-filter="searchMatterByFirm"
          :options="firmFilterOptions"
          options-label-key="name"
          options-value-key="billingFirmId">
        </filter-multiselect>
      </template>
    </filter-button>

    <filter-activity-date
      v-if="showFilter('activityDate')"
      class="bottom-10 right-5"
      :on-apply-filter="filterByActivityDate"
      :year-range="dateFilterYearsRange">
    </filter-activity-date>

    <filter-button
      v-if="showFilter('practiceAreas')"
      label="Internal Practice Area"
      class="right-5 bottom-10"
      :active="isActive('internal_practice_areas')">
      <template #default="{ isVisible }">
        <filter-multiselect
          :is-visible="isVisible"
          :on-apply-filter="filterByInternalPracticeArea"
          :options="internalPracticeAreas"
          options-label-key="name"
          options-value-key="name">
        </filter-multiselect>
      </template>
    </filter-button>

    <filter-button
      v-if="showFilter('reviewed')"
      label="Reviewed Matters"
      class="right-5 bottom-10"
      :active="isActive('reviewed')">
      <form role="form" class="a-form" novalidate>
        <div class="d-flex vertical-center justify-between">
          <div>
            Show matters with reviews only
          </div>

          <button type="button" :class="['toggle-button', { 'toggle-on': !!filterParams.reviewed }]" @click="filterByReviewed">
            {{ filterParams.reviewed ? 'On' : 'Off' }}
          </button>
        </div>
      </form>
    </filter-button>

    <filter-button
      v-if="showFilter('matterManagers')"
      label="Matter Manager"
      class="right-5 bottom-10"
      :active="isActive('matter_manager_ids')">
      <template #default="{ isVisible }">
        <filter-multiselect
          :is-visible="isVisible"
          :on-apply-filter="filterByMatterManager"
          :options="matterManagers"
          options-label-key="displayName"
          options-value-key="participationIds">
        </filter-multiselect>
      </template>
    </filter-button>
  </div>
</template>

<script>
import FilterActivityDate from 'vue-app/scout/client/matters/filter-activity-date.vue';
import FilterButton from 'vue-app/shared/components/filter-button.vue';
import FilterMultiselect from 'vue-app/shared/components/filter-popovers/filter-multiselect.vue';
import SearchInput from 'vue-app/shared/components/search-input.vue';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import ScoutFirmBridge from 'resources/scout/scout-firm-bridge.js';

export default {
  name: 'MattersListFilters',

  components: {
    FilterActivityDate,
    FilterButton,
    FilterMultiselect,
    SearchInput
  },

  props: {
    showFilters: {
      type: Array,
      required: true
    },

    dateFilterYearsRange: {
      type: Array,
      required: true
    },

    internalPracticeAreas: {
      type: Array,
      required: false
    },

    matterManagers: {
      type: Array,
      required: true
    },

    onApplyFilter: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      firmFilterOptions: [],
      filterParams: {
        billing_firm_ids: [],
        internal_practice_areas: null,
        matter_manager_ids: null,
        reviewed: null
      }
    };
  },

  mounted() {
    if (this.showFilter('firm')) {
      LoadingService.loading('firmFilterOptions');
      ScoutFirmBridge.query({ billing_firms: true, view: 'minimal' }).then((firms) => {
        this.firmFilterOptions = firms;
        LoadingService.done('firmFilterOptions');
      });
    }
  },

  methods: {
    showFilter(name) {
      return this.showFilters.includes(name);
    },

    isActive(filter) {
      return !!this.filterParams[filter]?.length;
    },

    searchMatterByNumber(code) {
      this.filterParams.code = code;
      this.applyFilter();
    },

    resetFilters() {
      delete this.filterParams.code;
      this.applyFilter();
    },

    searchMatterByFirm(ids) {
      this.filterParams.billing_firm_ids = ids;
      this.applyFilter();
    },

    applyFilter() {
      this.onApplyFilter(this.filterParams);
    },

    filterByActivityDate(params) {
      delete this.filterParams.active;

      this.filterParams = Object.assign(this.filterParams, params);
      this.applyFilter();
    },

    filterByInternalPracticeArea(ids) {
      this.filterParams.internal_practice_areas = ids;
      this.applyFilter();
    },

    filterByMatterManager(ids) {
      this.filterParams.matter_manager_ids = ids;
      this.applyFilter();
    },

    filterByReviewed() {
      this.filterParams.reviewed = this.filterParams.reviewed ? null : 'true';
      this.applyFilter();
    }
  }
};
</script>
